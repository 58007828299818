<template>
    <div :class="['plan-container', { 'plan-active': plan.id === currentPlan?.id }]">
        <div class="plan-header">
            <ColorTag
                :title="plan.name.replace('Yearly ', '')"
                :color="currentPlan?.id === plan.id ? 'green' : 'blue'"
                content=""
                style="display: inline-block"
            />
            <Spacer height="1rem" />
            <Text as="span" size="f-3" weight="600">${{ plan.price }}</Text>
            <Text as="span" size="f-5" weight="500">/{{ plan.period_interval }}</Text>
        </div>
        <div class="plan-items">
            <div class="plan-item">
                <PricingCheck :hex="currentPlan?.id === plan.id ? '#00A861' : '#006dff'" />
                <Spacer width="0.75rem" height="0.25rem" />
                <Text as="span" size="f-8" v-if="plan.account_limit">
                    <b>{{ plan.account_limit }}</b> Accounts
                </Text>
            </div>
            <div class="plan-item">
                <PricingCheck :hex="currentPlan?.id === plan.id ? '#00A861' : '#006dff'" />
                <Spacer width="0.75rem" height="0.25rem" />
                <Text as="span" size="f-8">
                    <b>{{ formatPrice(plan.spend_limit) }}</b> Spend/mo
                </Text>
            </div>
            <div class="plan-item">
                <PricingCheck :hex="currentPlan?.id === plan.id ? '#00A861' : '#006dff'" />
                <Spacer width="0.75rem" height="0.25rem" />
                <Text as="span" size="f-8" v-if="plan.id.includes(Billing.StandardPlan.Enterprise)">
                    Account Manager
                </Text>
                <Text
                    as="span"
                    size="f-8"
                    v-else-if="!plan.id.includes(Billing.StandardPlan.Basic)"
                >
                    Priority Support
                </Text>
                <Text as="span" size="f-8" v-else>Live Chat Support</Text>
            </div>
            <div class="plan-item">
                <PricingCheck :hex="currentPlan?.id === plan.id ? '#00A861' : '#006dff'" />
                <Spacer width="0.75rem" height="0.25rem" />
                <Text as="span" size="f-8">
                    <b v-if="plan.id.includes(Billing.StandardPlan.Enterprise)">6hr</b>
                    <b v-else-if="!plan.id.includes(Billing.StandardPlan.Basic)">12hr</b>
                    <b v-else>24hr</b> Refresh Cycle
                </Text>
            </div>
            <div class="plan-item">
                <svg
                    v-if="plan.id.includes(Billing.StandardPlan.Basic)"
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="9" cy="9" r="9" fill="#E0E0E5" />
                    <path
                        d="m11.5 6.5-5 5M6.5 6.5l5 5"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <PricingCheck :hex="currentPlan?.id === plan.id ? '#00A861' : '#006dff'" v-else />
                <Spacer width="0.75rem" height="0.25rem" />
                <Text as="span" size="f-8">Google Ads Reports</Text>
            </div>
        </div>
        <div class="plan-button">
            <oButton
                ref="selectPlanButton"
                @clicked="updateSubscription"
                :loading="updatingPlan"
                :success="plan.id === currentPlan?.id"
                color="blue"
                size="medium"
                class="w-100"
            >
                <span v-if="plan.id === currentPlan?.id">Active Plan</span>
                <span v-else-if="isEnterprisePlan">Talk to Sales</span>
                <span v-else>Select Plan</span>
            </oButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { computed, PropType, ref } from 'vue'
import { Billing } from '@opteo/types'
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'
import { oButton, ColorTag, Text, Spacer, useMoney } from '@opteo/components-next'
import PricingCheck from '@/components/global/PricingCheck.vue'

const props = defineProps({
    plan: {
        type: Object as PropType<Billing.CurrentPlan>,
        required: true,
    },
})

const { currentPlan, mutateUserInfo, totalSpend30d } = useUser()
const { mutate: mutateSubscription, mutateCoupon } = useSubscription()

const planID = computed(() => props.plan.id)

const updatingPlan = ref(false)

const selectPlanButton = ref()

const isEnterprisePlan = computed(() =>
    [
        Billing.StandardPlan.Enterprise,
        Billing.StandardPlan.YearlyEnterprise,
        Billing.LegacyPlan.Enterprise,
        Billing.LegacyPlan.YearlyEnterprise,
    ].includes(props.plan.id as Billing.StandardPlan)
)

async function updateSubscription() {
    if (updatingPlan.value || planID.value === currentPlan.value?.id) {
        return
    }

    if (isEnterprisePlan.value) {
        window
            .open('https://calendly.com/adamdale/opteo-enterprise-plan-upgrade', '_blank')
            ?.focus()

        return
    }

    updatingPlan.value = true

    try {
        await authRequest(Endpoint.UpdateStripePlan, {
            body: {
                new_plan_id: planID.value,
            },
        })

        const overLimit = props.plan.spend_limit
            ? totalSpend30d.value > props.plan.spend_limit * 1.05
            : false

        mutateUserInfo({ current_plan: props.plan, over_limit: overLimit })

        mutateSubscription()
        mutateCoupon()

        updatingPlan.value = false
        selectPlanButton.value.flashSuccess()
    } catch (err) {
        updatingPlan.value = false
        selectPlanButton.value.flashError()
    }
}

/**
 * @description Takes a raw number and formats it with a leading '$' sign.
 * @example
 * const formattedPrice = formatPrice(25_000)
 * console.log(formattedPrice) // Expected result: '$25,000'
 * @param {number | null} rawPrice
 * Note: `rawPrice` should never be null since we're sending info from existing plans, which have a defined number value.
 * The `Billing.CurrentPlan` type includes `null`s for old plans compatibility reasons.
 * @returns A formatted price (or '$0' if `null`)
 */
const formatPrice = (rawPrice: number | null) => {
    return useMoney({ value: rawPrice ?? 0, currency: 'USD', compact: false }).displayValue.value
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.plan-container {
    @include container;
    @include br-24;
    width: 15rem;
}

.plan-header {
    @include pa-24;
    border-bottom: 1px solid;
    @include opteo-border;
}

.plan-items {
    @include pa-24;
    border-bottom: 1px solid;
    @include opteo-border;
}

.plan-item {
    @include flex;
    @include items-center;
    @include mb-12;
}
.plan-item:last-child {
    @include mb-0;
}

.plan-button {
    @include ph-24;
    @include pv-20;
}
</style>
