import { computed, ref, Ref } from 'vue'

import { NgramTool } from '@opteo/types'
import { useRouter } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { LS_NGRAM_EXCLUDED_CAMPAIGNS } from '@/lib/cookies'
import { useAccount } from '@/composition/account/useAccount'

export type FilterKey =
    | 'oneWordNGrams'
    | 'twoWordNGrams'
    | 'threeWordNGrams'
    | 'stopWords'
    | 'lowVolume'
    | 'searchPartners'
    | 'searchTermsBlockedByNegatives'
    | 'pausedKeywords'
    | 'exactMatch'
    | 'exactMatchCloseVariant'
    | 'phraseMatch'
    | 'phraseMatchCloseVariant'
    | 'broadMatch'
    | 'industryPoorlyPerforming'
    | 'bounceRatePoorlyPerforming'

type NGramFilter = {
    [key in Partial<FilterKey>]: {
        label: string
        checked: boolean
        subLabel?: string | null
        filterActive?: (active: string) => boolean
    }
}

const createFilter = (
    label: string,
    key: FilterKey,
    checked: boolean = false,
    subLabel: string | null = null,
    filterActive?: (activeTab: string) => boolean
) =>
    ({
        [key]: {
            label,
            subLabel,
            checked,
            filterActive,
        },
    }) as NGramFilter

const searchCampaignFilter = (activeTab: string): boolean => activeTab === 'search-campaigns'

const excludeNGramFilters: Ref<NGramFilter> = ref({
    ...createFilter('One Word N-Grams', 'oneWordNGrams'),
    ...createFilter('Two Word N-Grams', 'twoWordNGrams'),
    ...createFilter('Three Word N-Grams', 'threeWordNGrams'),
    ...createFilter('Stop Words', 'stopWords', true, '(the, and, of)'),
    // ...createFilter('Low Volume N-Grams', 'lowVolume', true),
})

const excludeSearchTermFilters: Ref<NGramFilter> = ref({
    ...createFilter('Search Partners', 'searchPartners', false, null, activeTab => {
        return ['search-campaigns', 'shopping-campaigns'].includes(activeTab)
    }),
    ...createFilter('Search Terms Blocked by Negatives', 'searchTermsBlockedByNegatives', true),
})

const excludeKeywordFilters: Ref<NGramFilter> = ref({
    ...createFilter('Paused Keywords', 'pausedKeywords', false, null, searchCampaignFilter),
})

const excludeMatchTypeFilters: Ref<NGramFilter> = ref({
    ...createFilter('Exact Match', 'exactMatch', false, null, searchCampaignFilter),
    ...createFilter(
        'Exact Match (Close Variant)',
        'exactMatchCloseVariant',
        false,
        null,
        searchCampaignFilter
    ),
    ...createFilter('Phrase Match', 'phraseMatch', false, null, searchCampaignFilter),
    ...createFilter(
        'Phrase Match (Close Variant)',
        'phraseMatchCloseVariant',
        false,
        null,
        searchCampaignFilter
    ),
    ...createFilter('Broad Match', 'broadMatch', false, null, searchCampaignFilter),
})

const filtersModalOpen = ref(false)

const lookbackWindows: {
    label: string
    value: NgramTool.NgramLookbackWindow
}[] = [
    { label: 'Last 90 Days', value: 'LAST_90_DAYS' },
    { label: 'Last 180 Days', value: 'LAST_180_DAYS' },
    { label: 'Last 365 Days', value: 'LAST_365_DAYS' },
    { label: 'Last 2 Years', value: 'LAST_730_DAYS' },
]

const lookbackWindow = ref<{
    label: string
    value: NgramTool.NgramLookbackWindow
}>(lookbackWindows[0])

const stringifiedFilters = ref('')

const stringifiedCampaignSelection = ref('')

export function useNGramFilters() {
    const { currentRoute } = useRouter()
    const { accountId } = useAccount()

    const excludedCampaigns = useLocalStorage<string[] | null>(
        LS_NGRAM_EXCLUDED_CAMPAIGNS(accountId.value),
        null,
        {
            serializer: {
                read: (v: any) => (v ? JSON.parse(v) : null),
                write: (v: any) => JSON.stringify(v),
            },
        }
    )

    const activeTab = computed(
        () => currentRoute.value?.name?.toString().split('toolkitngramtool-')[1] as string
    )

    const totalCheckedFilters = computed(() => {
        return Object.values({
            ...excludeNGramFilters.value,
            ...excludeSearchTermFilters.value,
            ...excludeKeywordFilters.value,
            ...excludeMatchTypeFilters.value,
        }).filter(
            filter =>
                (!filter.filterActive || filter.filterActive(activeTab.value)) && filter.checked
        ).length
    })

    return {
        excludeNGramFilters,
        excludeSearchTermFilters,
        excludeKeywordFilters,
        excludeMatchTypeFilters,
        checkedFilters: totalCheckedFilters,
        filtersModalOpen,
        lookbackWindow,
        lookbackWindows,
        excludedCampaigns,
        activeTab,
        stringifiedFilters,
        stringifiedCampaignSelection,
    }
}
