<template>
    <Popout
        v-model="actionsPopoverOpen"
        :offset="[-4, -2]"
        :borderRadius="22"
        maxWidth="234px"
        containerClasses="popout-actions"
    >
        <SquareButton size="small" :disabled="loading">
            Actions
            <template #suffix>
                <SmallDownCaret class="select-arrow" aria-hidden="true" />
            </template>
        </SquareButton>
        <template #content>
            <SquareButton
                @click="
                    () => {
                        goToNegativeDestination()
                        // selectNgram(item.text)
                        if (!initialNgramSelection.includes(item.text)) {
                            initialNgramSelection.push(item.text)
                        }
                    }
                "
                v-if="currentStepIndex === 0"
                size="small"
                variant="phantom"
                :full-width="true"
            >
                Add as Negative Keyword
            </SquareButton>
            <SquareButton
                v-if="itemType !== 'searchTerm'"
                @click="setPanel(ActivePanel.SearchTerms, item)"
                size="small"
                variant="phantom"
                :full-width="true"
            >
                Preview Blocked Search Terms
            </SquareButton>
            <SquareButton
                v-if="tab.key === 'search-campaigns'"
                @click="setPanel(ActivePanel.Keywords, item)"
                size="small"
                variant="phantom"
                :full-width="true"
            >
                Preview Blocked Keywords
            </SquareButton>
            <!-- TODO (dev): add functionality for removeFromList -->
            <!-- <SquareButton
                @click="() => {}"
                v-if="currentStepIndex === 1"
                size="small"
                variant="phantom"
                :full-width="true"
                >Remove {{ AddNegatives.FormattedItemType[itemType] }} from List</SquareButton
            >
                Remove nGram from List
            </SquareButton> -->
        </template>
    </Popout>
</template>

<script setup lang="ts">
import { Popout, SquareButton } from '@opteo/components-next'
import SmallDownCaret from '@/components/linkedAccounts/icons/SmallDownCaret.vue'
import { ref, computed } from 'vue'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useNgramPanels, ActivePanel } from '@/composition/toolkit/nGramTool/useNgramPanels'
import { useRouter } from 'vue-router'
import { AddNegatives, PanelItem } from '@/composition/toolkit/nGramTool/types/index'

defineProps<{
    item: PanelItem
    itemType: AddNegatives.ItemType
    loading?: boolean
}>()

const { currentStepIndex, selectNgram, goToNegativeDestination, tabLinks, initialNgramSelection } =
    useNGramTool()
const { setPanel } = useNgramPanels()

const router = useRouter()
const tab = computed(() => {
    // Remove 'toolkitngramtool-' from the start and '-select' from the end
    const key = router.currentRoute.value.name
        ?.toString()
        .replace(/^toolkitngramtool-/, '') // Remove the prefix
        .replace(/-select$/, '') // Remove the suffix

    const tab = tabLinks.value.find(tab => tab.key === key)

    if (!key || !tab) return tabLinks.value[0]

    return tab
})

const actionsPopoverOpen = ref(false)
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.popout.popout-actions {
    @include pa-8;
}
</style>
