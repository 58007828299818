<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert?.title }}
    </Text>
    <Spacer height="1.5rem" />
    <Text as="p" size="f-8">
        No conversions recorded for <Ent>{{ conversionName }}</Ent
        >.
    </Text>
    <Spacer height="1rem" />
    <Text as="p" size="f-8">
        <span>
            On {{ month }}, conversions fell to 0. Based on previous data, we expected roughly
            <b><Number :value="expected" /></b> conversions for this day. The actual number of
            conversions for this day is highlighted in the graph below.
            <Spacer height="1rem" />
            Check Google Ads for billing issues, disapproved ads, conversion tracking issues or any
            other problems that might be responsible for this change.
            <Spacer height="1rem" />
            If this behaviour is expected, feel free to ignore this alert.
        </span>
    </Text>
    <Spacer height="2rem" />
    <div class="chart-container">
        <LineChart
            :series="chartData"
            :areas="redArea"
            :show-zero="true"
            :chart-height="180"
            disable-fallback
        />
    </div>
    <Spacer height="2rem" />
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Text, LineChart, Spacer, Number, LineChartTypes } from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import subtractDates from 'date-fns/sub'
import { Account, Alerts } from '@opteo/types'
import sortBy from 'lodash-es/sortBy'

import AlertNotes from '@/components/notes/AlertNotes.vue'
import Ent from '@/components/util/Ent.vue'
import { useAlert } from '@/composition/alerts/useAlert'

export default defineComponent({
    name: 'ConversionActionsFlatline',
    components: {
        AlertNotes,
        Text,
        LineChart,
        Spacer,
        Number,
        Ent,
    },
    setup() {
        const { alert, isCampaignLevel } = useAlert<
            Alerts.ConversionActionsFlatlineBody,
            Alerts.FlatlineChartData
        >()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const isoDate = parseISO(alert.value.body.date)

        isoDate.setDate(isoDate.getDate() - 3) // because we look at the first day of the conversions drop

        const dayNumber = format(parseISO(alert.value.body.date), 'do')
        const month = format(parseISO(alert.value.body.date), 'LLLL do')

        const expected = alert.value.body.expected

        const { conversionId, conversionName } = alert.value.body

        const chartData: LineChartTypes.LineChartSeries[] = [
            {
                name: conversionName,
                items: sortBy(alert.value?.chart, row => parseISO(row.day).valueOf())
                    .map(row => {
                        return {
                            x: parseISO(row.day),
                            y: row[conversionId],
                        }
                    })
                    .slice(-11),
            },
        ]

        const redArea = [
            { axis: 'x', start: subtractDates(isoDate, { days: 1 }), end: isoDate, color: 'red' },
        ]

        return {
            alert,
            isCampaignLevel,
            chartData,
            dayNumber,
            month,
            expected,
            isoDate,
            redArea,
            conversionName,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include pa-24;
}
</style>
