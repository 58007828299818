import { ref, computed, watch } from 'vue'

import { Targets } from '@opteo/types'
import { useCampaignGroups } from './useCampaignGroups'
import { authRequest, Endpoint } from '../api/useAPI'
import { useDomain } from '../domain/useDomain'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useAccountSetupBar } from '../account/useAccountSetupBar'

interface ConversionActionTableItems {
    action: string
    actionId: number
    enabled: boolean
    category: string
    conversions: number
    conversionsValue: number
    type: 'Primary' | 'Secondary'
}

export function useCampaignGroup(props: { group: Targets.CampaignGroup }) {
    /*
        Other Composables
    */
    const {
        mutateCampaignGroup,
        selectedCampaigns,
        deleteGroup,
        isUsingCpa,
        isUsingRoas,
        campaignTableHeaders,
        campaignGroups,
        accountPlatform,
    } = useCampaignGroups()

    const intercom = useIntercom()
    const { domainId, currencySymbol } = useDomain()

    /*
        Generic function to update a field in a campaign group
    */

    const updateCampaignGroup = async (fields: Targets.EditableCampaignGroupFields) => {
        if (fields.desiredCpa) {
            intercom.trackEvent('set_cpa_target', { domain_id: domainId.value })
        }

        const updatedCampaignGroup: Targets.CampaignGroup = {
            ...props.group,
            ...fields,
        }

        const performanceTarget = fields.desiredCpa ?? fields.desiredRoas

        if (performanceTarget) updatedCampaignGroup.performanceTarget = performanceTarget

        if (fields.targetMode === Targets.TargetMode.AUTO || fields.conversionActions) {
            await authRequest(Endpoint.UpdateCampaignGroup, {
                body: {
                    campaignGroupId: props.group.id,
                    fields,
                },
            })

            mutateCampaignGroup()
            return
        }

        mutateCampaignGroup(updatedCampaignGroup)

        await authRequest(Endpoint.UpdateCampaignGroup, {
            body: {
                campaignGroupId: props.group.id,
                fields,
            },
        })
    }

    /*
        Group Rename
    */
    const nameUpdateModalShown = ref(false)
    const newName = ref(props.group.name)
    const renameGroupInput = ref()

    const openRenameGroupModal = () => (nameUpdateModalShown.value = true)

    const renameGroup = async (values: { newName: string }) => {
        await updateCampaignGroup({ name: values.newName.trim() })
        nameUpdateModalShown.value = false
    }

    const existingNames = computed(() =>
        campaignGroups.value?.filter(group => group.id !== props.group.id).map(group => group.name)
    )

    function nameIsAlreadyTaken(value: string) {
        if (existingNames.value?.includes(value)) return 'This name is already in use.'
    }

    /*
        Conversion Actions Logic
    */
    const conversionActions = computed(() => props.group.conversionActions)

    const conversionActionTableHeaders = [
        {
            key: 'action',
            text: 'Conversion Action',
            sortable: false,
            noPadding: true,
        },
        {
            key: 'conversions',
            text: 'Conv.',
            sortable: true,
            noPadding: true,
            width: 108,
        },
        {
            key: 'conversionsValue',
            text: 'Value',
            sortable: true,
            noPadding: true,
            width: 116,
        },
        {
            key: 'type',
            text: 'Type',
            sortable: true,
            noPadding: true,
            width: 112,
        },
    ]

    const conversionActionTableItems = ref<ConversionActionTableItems[]>()

    function handleShowConversionActionPanel() {
        conversionActionTableItems.value =
            conversionActions.value?.map(row => {
                return {
                    action: row.conversionActionName,
                    actionId: row.conversionActionId,
                    enabled: !!row.enabled,
                    category: row.conversionActionCategory,
                    conversions: row.conversions ?? 0,
                    conversionsValue: row.conversionsValue ?? 0,
                    type: row.isPrimary ? 'Primary' : 'Secondary',
                }
            }) ?? []

        showConversionActionPanel.value = true
    }

    const showConversionActionPanel = ref(false)
    const { setupActive, hideAccountSetupBar, showAccountSetupBar } = useAccountSetupBar()

    const enabledConversionsCount = computed(
        () => conversionActions.value?.filter(c => c.enabled).length ?? 0
    )

    const allConversionsChecked = computed(
        () =>
            conversionActionTableItems.value?.filter(ca => ca.enabled).length ===
            conversionActionTableItems.value?.length
    )

    const updateConversionAction = (conversionActionId: number, value: boolean) => {
        const conversionAction = conversionActionTableItems.value?.find(
            ca => ca.actionId === conversionActionId
        )

        if (!conversionAction) throw new Error('Invalid conversion action')

        conversionAction.enabled = value
    }

    const toggleAllConversions = () => {
        const enabled = !allConversionsChecked.value
        conversionActionTableItems.value?.forEach(conv => {
            updateConversionAction(conv.actionId, enabled)
        })
    }

    const saveConversionChanges = async () => {
        const updatedConversionActions = conversionActions.value.map(ca => {
            const enabled =
                conversionActionTableItems.value?.find(
                    _ca => _ca.actionId === ca.conversionActionId
                )?.enabled ?? false

            return {
                ...ca,
                enabled,
            }
        })

        await updateCampaignGroup({ conversionActions: updatedConversionActions })
        showConversionActionPanel.value = false
    }

    /*
        Optimisation Target Logic
    */
    const showTargetPanel = ref<boolean>(false)
    const autoTarget = ref<boolean>()
    const customTarget = ref<number>()

    function handleShowTargetPanel() {
        autoTarget.value = props.group.targetMode === Targets.TargetMode.AUTO ?? false

        const roundingFactor = isUsingRoas.value ? 10_000 : 100

        customTarget.value = Math.round(roundingFactor * props.group.performanceTarget) / 100

        showTargetPanel.value = true
    }

    const updateTarget = async () => {
        const desiredCpa = isUsingCpa.value ? customTarget.value : undefined
        const desiredRoas = isUsingRoas.value ? (customTarget.value ?? 0) / 100 : undefined

        if (!autoTarget.value && (!customTarget.value || customTarget.value < 1)) {
            // Minimum value is 1, and the oInput validation does not prevent this function from being called.
            console.warn('Invalid target value')
            return
        }

        await updateCampaignGroup({
            desiredCpa,
            desiredRoas,
            targetMode: autoTarget.value ? Targets.TargetMode.AUTO : Targets.TargetMode.CUSTOM,
        })

        showTargetPanel.value = false
    }

    const isAutoMode = computed(() => props.group.targetMode === Targets.TargetMode.AUTO)

    /*
        Toggling Campaigns to move them to a new group logic
    */
    const allCampaignsSelected = computed(() => {
        const selectedCampaignIds = selectedCampaigns.value.map(c => c.campaignId)

        return filteredCampaigns.value?.every(c => selectedCampaignIds.includes(c.campaignId))
    })

    const toggleAllCampaigns = () => {
        const shouldCheck = !allCampaignsSelected.value

        for (const campaign of filteredCampaigns.value ?? []) {
            toggleCampaign(campaign, shouldCheck)
        }
    }

    const isCampaignSelected = (campaignId: string) => {
        const selectedCampaignIds = selectedCampaigns.value.map(c => c.campaignId)

        return selectedCampaignIds.includes(campaignId)
    }

    const toggleCampaign = (campaign: Targets.CampaignGroupCampaign, checked: boolean) => {
        selectedCampaigns.value = selectedCampaigns.value.filter(
            _campaign => _campaign.campaignId !== campaign.campaignId
        )

        if (checked) {
            selectedCampaigns.value.push(campaign)
        }
    }

    const editPopout = ref(false)
    const toggleEdit = () => {
        editPopout.value = !editPopout.value
    }

    const tooltipContent = computed(() => {
        if (props.group.campaigns.length > 0)
            return 'Please empty this campaign group before deleting'
    })

    /*
        Search functionality
    */
    const campaignSearch = ref('')

    const filteredCampaigns = computed(() =>
        props.group.campaigns
            .map(campaign => {
                return {
                    campaignCpa: campaign.cost > 0 ? campaign.cost / campaign.conversions : 0,
                    campaignRoas:
                        campaign.conversionsValue > 0
                            ? campaign.conversionsValue / campaign.cost
                            : 0,
                    ...campaign,
                }
            })
            .filter(campaign =>
                campaign.campaignName.toLowerCase().includes(campaignSearch.value.toLowerCase())
            )
    )

    return {
        toggleCampaign,
        renameGroup,
        openRenameGroupModal,
        renameGroupInput,
        nameUpdateModalShown,
        deleteGroup,
        showTargetPanel,
        showConversionActionPanel,
        autoTarget,
        updateTarget,
        newName,
        enabledConversionsCount,
        conversionActions,
        allConversionsChecked,
        toggleAllConversions,
        allCampaignsSelected,
        toggleAllCampaigns,
        saveConversionChanges,
        isAutoMode,
        isUsingCpa,
        isUsingRoas,
        editPopout,
        toggleEdit,
        tooltipContent,
        currencySymbol,
        updateConversionAction,
        isCampaignSelected,
        customTarget,
        campaignTableHeaders,
        campaignSearch,
        filteredCampaigns,
        conversionActionTableHeaders,
        conversionActionTableItems,
        handleShowConversionActionPanel,
        handleShowTargetPanel,
        nameIsAlreadyTaken,
        accountPlatform,
    }
}
