<template>
    <div class="wrapper">
        <div class="client-selector desktop-only">
            <div>
                <Avatar
                    v-if="accountInfo?.name"
                    :color="accountInfo.color"
                    :platform="Platform.Platform.GoogleAds"
                    show-account-type
                >
                    {{ accountInitials }}
                </Avatar>
                <Skeleton v-else :width="36" :height="36" />
            </div>
            <p
                v-if="accountInfo?.name"
                class="client-name"
                :style="{ color: accountInfo.color }"
            >
                {{ accountInfo?.name }}
            </p>
            <div class="client-selector" v-else>
                <Spacer width="0.875rem" />
                <Skeleton :width="Math.random() * 80 + 100" />
            </div>
        </div>

        <!-- BUTTONS -->
        <div class="buttons-wrapper">
            <oButton @clicked="closeModal()" color="white" :disabled="loading"> Cancel </oButton>
            <Spacer width="0.75rem" class="desktop-only" />
            <oButton @clicked="unlinkGoogleAnalytics" color="white" :disabled="loading">
                Unlink Account
            </oButton>
            <Spacer width="0.75rem" class="desktop-only" />
            <oButton @clicked="linkView()" color="blue" :disabled="viewSelected || loading">
                Connect to Opteo
            </oButton>
        </div>
    </div>
</template>
<script lang="ts">
import { oButton, Spacer, Avatar } from '@opteo/components-next'
import { Platform } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount';
import Skeleton from '@/components/util/Skeleton.vue'

export default {
    props: {
        viewSelected: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        oButton,
        Avatar,
        Spacer,
        Skeleton,
    },
    emits: ['unlink-account', 'close-modal', 'link-view'],

    setup(_, { emit }) {
        const { accountInfo, accountInitials } = useAccount()

        return {
            unlinkGoogleAnalytics() {
                emit('unlink-account')
            },
            closeModal() {
                emit('close-modal')
            },
            linkView() {
                emit('link-view')
            },
            accountInfo,
            accountInitials,
            Platform,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.wrapper {
    @include w-100;
    @include flex;
    @include justify-between;
}

.buttons-wrapper {
    @include flex;
}

.analytics-footer {
    z-index: 300001;
    transition: transform 0.4s ease;
    position: fixed;
    bottom: 2.5rem;
    width: calc(100% - 5rem);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.client-selector {
    @include flex;
    @include items-center;
}

.client-name {
    @include f-7;
    @include fw-500;
    @include ml-16;
    transform: translateY(-1px);
}

@media (max-width: $mq-480-max) {
    .desktop-only {
        display: none;
    }

    .wrapper {
        flex-direction: column;
    }

    .buttons-wrapper {
        flex-direction: column;
        gap: 0.75rem;
    }
}
</style>
