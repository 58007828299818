<template>
    <div class="invoice-settings-modal">
        <!-- Invoice Recipient -->
        <Text as="h4" weight="600" size="f-5" class="invoice-section-title">
            Invoice Recipient
        </Text>
        <Spacer height="2rem" class="invoice-section-title" />
        <div class="invoice-input">
            <oInput
                id="admin-email"
                ref="admin_email_field"
                :prefill="true"
                v-model="vatData.adminEmail"
                class="w-100"
                type="email"
                label="Email Address"
            />
        </div>
        <Spacer :height="aboveMobile ? '3rem' : '1.5rem'" />
        <!-- VAT Number -->
        <div class="flex items-center">
            <Text as="h4" weight="600" size="f-5" class="invoice-section-title">VAT Number</Text>
            <Tooltip
                content="Only applicable to EU countries and UK"
                class="invoice-section-title-tooltip"
            >
                <img
                    id="tippy-info-icon"
                    src="@/assets/img/icon-info.svg"
                    class="ml2"
                    style="width: 16px; height: 16px"
                />
            </Tooltip>
        </div>
        <Spacer height="2rem" class="invoice-section-title" />
        <div class="invoice-input">
            <oInput ref="vatNumberField" v-model="vatNumber" type="text" label="VAT Number" />
        </div>
        <Spacer :height="aboveMobile ? '3rem' : '1.5rem'" />
        <!-- VAT Address -->
        <div>
            <Text as="h4" weight="600" size="f-5" class="invoice-section-title">
                Invoice Address
            </Text>
            <Spacer height="2rem" class="invoice-section-title" />
            <div class="invoice-input">
                <oInput
                    id="company-name"
                    :prefill="true"
                    v-model="vatData.invoiceAddress.company_name"
                    class="mb-24"
                    type="text"
                    label="Company Name"
                />
                <oInput
                    id="street-line-1"
                    :prefill="true"
                    v-model="vatData.invoiceAddress.street_line_1"
                    class="mb-24"
                    type="text"
                    label="Address Line 1"
                />
                <oInput
                    id="street-line-2"
                    :prefill="true"
                    v-model="vatData.invoiceAddress.street_line_2"
                    class="mb-24"
                    type="text"
                    label="Address Line 2"
                />
                <oInput
                    id="city"
                    :prefill="true"
                    v-model="vatData.invoiceAddress.city"
                    class="mb-24"
                    type="text"
                    label="City"
                />
                <oInput
                    id="region"
                    :prefill="true"
                    v-model="vatData.invoiceAddress.region"
                    class="mb-24"
                    type="text"
                    label="Region"
                />
                <oInput
                    id="post-code"
                    v-model="vatData.invoiceAddress.postal_code"
                    class="mb-24"
                    type="text"
                    label="Postcode"
                />
                <oInput
                    v-model="vatData.invoiceAddress.country_code"
                    :items="countriesObject"
                    class="w-100"
                    label="Country"
                    type="select"
                    @updated="selectVatAddressCountry"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { oButton, oInput, Text, Tooltip, Spacer } from '@opteo/components-next'

import { useInvoicesSettings } from '@/composition/billing/invoices/useInvoicesSettings'
import { InvoicesVatDataProps } from '@/composition/billing/invoices/useInvoices'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'InvoicesVatNumber',
    props: {
        vatData: {
            type: Object as PropType<InvoicesVatDataProps>,
            required: true,
        },
    },
    setup(props: { vatData: InvoicesVatDataProps }) {
        const { aboveMobile } = useMediaQuery()

        return {
            aboveMobile,
            ...useInvoicesSettings(props),
        }
    },
    components: {
        oInput,
        oButton,
        Tooltip,
        Text,
        Spacer,
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.invoice-settings-modal {
    max-width: 100%;
    margin: 0 auto;
}
.invoice-section-title {
    display: none;
}
.invoice-section-title-tooltip {
    display: none;
}

@media (min-width: $mq-768-min) {
    .invoice-settings-modal {
        max-width: 24rem;
        margin: 2rem auto;
    }
    .invoice-input {
        @include container;
        @include pa-28;
    }
    .invoice-input:last-child {
        margin: 1rem auto 0 auto;
    }
    .invoice-section-title {
        display: block;
        letter-spacing: -0.0375rem;
    }
    .invoice-section-title-tooltip {
        display: inline;
    }
}
</style>
