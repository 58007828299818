<template>
    <Modal
        title="Negative Lists"
        v-model="negativeListModalOpen"
        width="852px"
        :noInnerContentShadow="false"
        :no-padding="true"
        @opened="focusNegativeSearch"
    >
        <template #content>
            <div class="negative-list-wrapper">
                <div class="negative-list-container">
                    <div class="negative-list-header">
                        <oInput
                            ref="listSearch"
                            name="listSearch"
                            class="list-search-input"
                            v-model="searchQuery"
                            autocomplete="off"
                        />
                        <Popout
                            v-model="showCampaignsPopout"
                            :trapFocus="true"
                            :offset="[0, 8]"
                            :zIndex="9999999999999"
                            placement="bottom"
                            :maxWidth="440"
                            @shown="focusNameInput"
                            :borderRadius="24"
                            :external-toggle-button="true"
                        >
                            <SquareButton
                                size="small"
                                @click="showCampaignsPopout = !showCampaignsPopout"
                                >Create Negative List</SquareButton
                            >
                            <template #content>
                                <div class="create-negative-list-popout">
                                    <oInput
                                        ref="listNameInput"
                                        name="newNegativeListName"
                                        label="New Negative List Name"
                                        v-model="newNegativeListName"
                                        autocomplete="off"
                                        type="text"
                                    />
                                    <Spacer height="1.25rem" />
                                    <Label>Add to Campaigns</Label>
                                    <div class="search-campaigns-list">
                                        <div class="campaigns-search-input">
                                            <oInput
                                                name="searchCampaigns"
                                                class="list-search-input"
                                                v-model="campaignSearchQuery"
                                                autocomplete="off"
                                            />
                                        </div>
                                        <div
                                            v-if="
                                                campaignSearchQuery.length &&
                                                campaignsLists.length === 0
                                            "
                                            class="campaigns-list-empty-state"
                                        >
                                            <Text as="h2" size="f-2">
                                                {{ campaignSearchQuery }}
                                            </Text>

                                            <Text size="f-8" align="center"
                                                >No campaigns match search query.</Text
                                            >
                                        </div>
                                        <PerfectScrollbar
                                            v-else
                                            tag="ul"
                                            class="campaigns-list"
                                            :options="{ suppressScrollX: true }"
                                        >
                                            <li
                                                v-for="(item, index) in campaignsLists"
                                                :key="index"
                                                class="campaigns-list-item"
                                                @click="toggleCampaign(item.campaign_id)"
                                            >
                                                <Checkbox
                                                    :model-value="
                                                        selectedCampaigns.includes(item.campaign_id)
                                                    "
                                                />
                                                <EntityPill
                                                    :type="Improvement.LocationEntity.Campaign"
                                                    :content="item.campaign_name"
                                                />
                                            </li>
                                        </PerfectScrollbar>
                                    </div>

                                    <div class="negative-list-creation-buttons">
                                        <oButton
                                            size="small"
                                            color="white"
                                            @clicked="showCampaignsPopout = false"
                                            >Cancel</oButton
                                        >

                                        <oButton
                                            size="small"
                                            color="blue"
                                            @clicked="createNegativeList"
                                            :loading="creatingNegativeList"
                                            :disabled="
                                                selectedCampaigns.length === 0 ||
                                                !newNegativeListName.length
                                            "
                                            >Create Negative List</oButton
                                        >
                                    </div>
                                </div>
                            </template>
                        </Popout>
                    </div>

                    <ul v-if="filteredNegativeLists?.length" class="negative-list-list">
                        <li v-for="item in filteredNegativeLists" class="negative-list-item">
                            <Tooltip
                                :visibleOnlyWhenOverflow="true"
                                :content="item.shared_set_name"
                                :offset="[-8, 20]"
                                :delay="[400, 0]"
                                :zIndex="9999999999999"
                            >
                                <EntityPill
                                    :type="Improvement.LocationEntity.NegativeList"
                                    :content="item.shared_set_name"
                                />
                            </Tooltip>
                        </li>
                    </ul>

                    <!-- Search Query doesn't match any negative list -->
                    <div v-else-if="searchQuery.length" class="negative-list-empty-state">
                        <Text as="h2" size="f-2">
                            {{ searchQuery }}
                        </Text>
                        <Text size="f-8" align="center">
                            No negative lists matching search query.
                        </Text>
                    </div>

                    <!-- No negative lists in the account -->
                    <div v-else class="negative-list-empty-state">
                        <Text size="f-8" align="center">No negative lists detected.</Text>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import {
    Modal,
    oInput,
    EntityPill,
    SquareButton,
    Popout,
    Tooltip,
    Checkbox,
    Label,
    Spacer,
    Text,
    oButton,
} from '@opteo/components-next'
import { useNGramNegativeLists } from '@/composition/toolkit/nGramTool/useNGramNegativeLists'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { Improvement } from '@opteo/types'
import { delay } from '@/lib/globalUtils'

import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { Gads } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'

const { negativeListModalOpen } = useNGramNegativeLists()

const { sharedSetData, campaignData, mutateSharedSetData } = useNGramTool()

const { accountId, domainId } = useAccount()

const searchQuery = ref('')
const filteredNegativeLists = computed(() => {
    if (!searchQuery.value.length) return sharedSetData.value

    return (
        sharedSetData.value?.filter(item =>
            item.shared_set_name.toLowerCase().includes(searchQuery.value.toLowerCase())
        ) ?? []
    )
})

const showCampaignsPopout = ref(false)

const newNegativeListName = ref('')
const campaignSearchQuery = ref('')

const listSearch = ref()
const listNameInput = ref()

const campaignsLists = computed(() => {
    if (!campaignSearchQuery.value.length) return campaignData.value

    return campaignData.value.filter(campaign =>
        campaign.campaign_name.toLowerCase().includes(campaignSearchQuery.value.toLowerCase())
    )
})

const selectedCampaigns = ref<string[]>([])

function toggleCampaign(campaignId: string) {
    if (selectedCampaigns.value.includes(campaignId)) {
        selectedCampaigns.value = selectedCampaigns.value.filter(c => c !== campaignId)
    } else {
        selectedCampaigns.value.push(campaignId)
    }
}

const creatingNegativeList = ref(false)

async function createNegativeList() {
    if (creatingNegativeList.value) return

    creatingNegativeList.value = true

    try {
        const sharedSetExistsByName = sharedSetData.value?.find(
            existingSet => existingSet.shared_set_name === newNegativeListName.value
        )

        if (sharedSetExistsByName) {
            throw new Error('Shared set already exists.')
        }

        await authRequest(Endpoint.CreateCustomSharedSetWithCampaigns, {
            body: {
                account_id: accountId.value,
                name: newNegativeListName.value,
                campaign_ids: selectedCampaigns.value,
                domain_id: domainId.value,
            },
        }).catch(e => {
            creatingNegativeList.value = false
            const err = e?.message ?? 'Unknown error occured'
            throw new Error(err)
        })

        await mutateSharedSetData()

        showCampaignsPopout.value = false
    } catch (e: any) {
        listNameInput.value?.setError(e.message)
    } finally {
        creatingNegativeList.value = false
    }
}

const focusNegativeSearch = async () => {
    await delay(10)
    listSearch.value.inputRef.focus()
}

const focusNameInput = async () => {
    await delay(10)
    listNameInput.value.inputRef.focus()
}

watch(negativeListModalOpen, isOpen => {
    if (isOpen) {
        searchQuery.value = ''
    }
})

watch(showCampaignsPopout, isOpen => {
    if (isOpen) {
        newNegativeListName.value = ''
        campaignSearchQuery.value = ''
        selectedCampaigns.value = []
    }
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.negative-list-wrapper {
    min-height: calc(100vh - 168px);
    @include pv-32;
}

.negative-list-container {
    max-width: 480px;
    margin: 0 auto;
    @include container;
    @include br-24;
}

.negative-list-header {
    padding: 20px 18px;
    @include ph-20;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
    border-bottom: 1px solid;
    @include opteo-border;
}
.negative-list-list {
    padding: 24px 22px;
    @include ph-24;
}

.list-search-input {
    height: 40px;
    @include relative;
}

.negative-list-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.negative-list-item {
    padding: 14px 18px;
    @include container;
    @include br-20;
    position: relative;
    overflow: hidden;
}

.negative-list-item::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    position: absolute;
    right: -2rem;
    top: 0;
    height: 100%;
    width: 5rem;
    z-index: 0;
}

.list-search-input :deep(input) {
    padding: 10px 14px 10px 34px;
}
.list-search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}

.create-negative-list-popout {
    @include pa-20;
    min-width: 440px;
}

.search-campaigns-list {
    @include container;
    overflow: hidden;
}
.campaigns-search-input {
    @include pv-16;
    @include pv-12;
    border-bottom: 1px solid;
    @include opteo-border;
}

.campaigns-list-search :deep(input) {
    padding: 10px 14px 10px 34px;
}
.campaigns-list-search::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}
.campaigns-search-input {
    padding: 18px 20px;
}

.campaigns-list {
    max-height: 303px;
    height: 100%;
}

.campaigns-list-item {
    cursor: pointer;
    padding: 14px 24px;
    display: flex;
    column-gap: 0.875rem;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.negative-list-item::after,
.campaigns-list-item::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    position: absolute;
    right: -2rem;
    top: 0;
    height: 100%;
    width: 5rem;
    z-index: 0;
}

.campaigns-list-item:not(:last-of-type) {
    border-bottom: 1px solid;
    @include opteo-border;
}

.campaigns-list-empty-state {
    @include flex-center;
    flex-direction: column;
    gap: 1.125rem;
    width: 100%;
    padding: 2.75rem 0;
}

.negative-list-empty-state {
    @include flex-center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 6rem 0;
}

.negative-list-creation-buttons {
    @include flex;
    @include pt-20;
    gap: 0.5rem;
    justify-content: end;
}
</style>
