<template>
    <div class="open-ad-button">
        <Tooltip :content="disabledReason ?? ''" :offset="[0, 20]" :delay="[100, 0]">
            <oButton
                size="small"
                color="white"
                @clicked="() => openRsa(rsaAdId)"
                :disabled="!!disabledReason"
            >
                Open Ad
            </oButton>
        </Tooltip>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { oButton, Tooltip } from '@opteo/components-next'

import { openInNewTab } from '@/lib/globalUtils'
import { QueryParam } from '@/router/routes'

import type { RsaWriter } from '@opteo/types'
import type { Mode } from '@/composition/toolkit/scorecard/types'

const props = defineProps<{
    rsaAdId: number
    adGroupId: number
    campaignId: string
    enabledRsas: RsaWriter.GadsRsa[] | undefined
    mode: Mode
    unsupported: boolean
}>()

const DisabledReason = {
    live: 'Login to open in RSA Writer',
    unsupported: 'Unsupported in RSA Writer',
    noRsa: 'Ad not in RSA Writer',
} as const

const disabledReason = computed(() => {
    if (props.mode === 'live') {
        return DisabledReason.live
    }

    if (props.unsupported) {
        return DisabledReason.unsupported
    }

    const isRsaInRsaWriter = props.enabledRsas?.some(enabledRsa => {
        const { id: enabledRsaAdId } = enabledRsa.ad_group_ad.ad

        return enabledRsaAdId === props.rsaAdId
    })

    if (!isRsaInRsaWriter) {
        return DisabledReason.noRsa
    }

    return null
})

const router = useRouter()

const openRsa = (rsaAdId: number) => {
    const { path } = router.currentRoute.value

    const cutOffIndex = path.indexOf('/scorecard')

    const toolkitPath = path.slice(0, cutOffIndex)

    const toolkitPathRegex = /user\/([^\/]+)\/account\/([^\/]+)\/toolkit\/tools/

    // Check that the resulting path is indeed /user/${userId}/account/${accountId}/toolkit/tools
    const isToolkitPathValid = toolkitPathRegex.test(toolkitPath)

    if (!isToolkitPathValid) {
        throw new Error(`Invalid toolkit path: ${toolkitPath}`)
    }

    const rsaWriterPath = `${toolkitPath}/rsa-writer/campaign/${props.campaignId}/ad-group/${props.adGroupId}/edit-ad/${rsaAdId}`

    const { href: editRsaHref } = router.resolve({
        path: rsaWriterPath,
        query: { from: QueryParam.from.scorecard },
    })

    openInNewTab(editRsaHref)
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.open-ad-button :deep(.o-button.small) {
    @include br-12;
    padding: 1px 14px 0 14px;
    height: 36px;
}
</style>
