import { Scorecard } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI } from '@/composition/api/useAPI'

export function useScorecardList() {
    const { accountId } = useAccount()

    const {
        data: scorecardData,
        loading: loadingScorecardList,
        error,
        mutate: mutateScorecardList,
    } = useAPI<{
        scorecardList: Scorecard.ScorecardListItem[]
        scorecardHistory: Scorecard.ScorecardListItem[]
        coverPageData: Scorecard.CoverPageData & { userId: number }
    }>(Endpoint.GetScorecardList, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    return {
        scorecardData,
        loadingScorecardList,
        mutateScorecardList,
        error,
    }
}
