<template>
    <div class="report-footer">
        <div class="flex items-center">
            <div class="avatar-container">
                <Tooltip :content="accountInfo?.name" placement="top-start">
                    <Avatar
                        :color="accountInfo?.color"
                        show-account-type
                        account-type-size="small"
                    >
                        {{ accountInitials }}
                    </Avatar>
                </Tooltip>
            </div>
            <div>
                <Text v-if="title" as="h4" class="report-footer-title">
                    {{ title }}
                </Text>
                <Spacer v-if="subTitle" height="0.375rem" />
                <Text
                    v-if="subTitle"
                    size="f-9"
                    weight="400"
                    color="gray"
                    style="line-height: 0.9375rem"
                >
                    {{ subTitle }}
                </Text>
            </div>
        </div>

        <!-- Buttons -->
        <div class="inline-flex justify-center justify-between relative w-100 w-auto">
            <div class="items-center pv3 pv0 w-auto">
                <slot>BUTTONS HERE</slot>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Avatar, oButton, Text, Spacer, Tooltip } from '@opteo/components-next'

import { useAccount } from '@/composition/account/useAccount';

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        subTitle: {
            type: String,
            required: false,
            default: '',
        },
    },
    components: { oButton, Avatar, Text, Spacer, Tooltip },
    setup() {
        const { accountInfo, accountInitials } = useAccount()

        return {
            accountInfo,
            accountInitials,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-footer {
    @include fixed;
    left: 1rem;
    bottom: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    @include container;
    @include z-10;
    box-shadow: $opteo-shadow-xl;
    @include pv-16;
    @include ph-24;
    @include br-24;
}
.report-footer-title {
    font-size: 1.0625rem;
    letter-spacing: -0.025rem;
    @include fw-600;
}
.report-footer .avatar-container {
    @include mr-20;
    @include block;
}
</style>
