<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        section-type="disapprovals"
        section-name="Disapprovals"
        :mode="mode"
        @score-history-button-clicked="$emit('scoreHistoryButtonClicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>

            <Spacer height="1rem" />

            <!-- Disapproval Summary -->
            <SubSectionHeader>
                <Text as="h6" weight="600">Disapproval Summary</Text>
                <oButton
                    v-if="!isPdf && aboveiPadLandscape"
                    @clicked="openBreakdownPanel"
                    :disabled="!hasDisapprovals"
                    :loading="breakdownLoading"
                    color="white"
                    size="small"
                >
                    View All Disapprovals
                </oButton>
            </SubSectionHeader>

            <DisapprovalStatusBlock
                :class="{ 'pdf-shadow-fix': isPdf }"
                entity="Ad"
                :total-number-of-entities="totalNumberOfAds"
                :number-of-disapproved-entities="totalNumberOfAdsDisapproved"
            />
            <Spacer height="1.125rem" />
            <DisapprovalStatusBlock
                :class="{ 'pdf-shadow-fix': isPdf }"
                entity="Keyword"
                :total-number-of-entities="totalNumberOfKeywords"
                :number-of-disapproved-entities="totalNumberOfKeywordsDisapproved"
            />
            <Spacer height="1.125rem" />
            <DisapprovalStatusBlock
                :class="{ 'pdf-shadow-fix': isPdf }"
                entity="Extension"
                :total-number-of-entities="totalNumberOfExtensions"
                :number-of-disapproved-entities="totalNumberOfExtensionsDisapproved"
            />

            <Spacer height="2.5rem" />

            <!-- Disapprovals & Restrictions -->
            <SubSectionHeader>
                <Text as="h6" weight="600">Disapprovals & Restrictions</Text>
            </SubSectionHeader>

            <!-- has disapprovals -->
            <oTable
                v-if="hasDisapprovals"
                :headers="disapprovalTableHeaders"
                :items="disapprovalTableItems"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
                responsive-col-size="40% 1fr"
            >
                <template #header.location>
                    <div class="header-cell">Location</div>
                </template>
                <template #column.location="cellData">
                    <div class="column-overlay">
                        <LocationCellPopout
                            :entity-name="
                                cellData.row.type === 'keyword'
                                    ? cellData.row.name
                                    : `ID: ${cellData.row.id}`
                            "
                            :entity-type="
                                cellData.row.type === 'ad' || cellData.row.type === 'keyword'
                                    ? cellData.row.type
                                    : 'extension'
                            "
                            :adgroup="cellData.row.locationPopout.adgroup?.name"
                            :campaign="cellData.row.locationPopout.campaign?.name"
                        >
                            <EntityPill
                                :type="cellData.row.locationPopout.entity"
                                :content="cellData.value"
                                :tooltip="false"
                                cursor="default"
                            />
                        </LocationCellPopout>
                    </div>
                </template>
                <template #column.type="cellData">
                    <div class="type-cell">
                        <EntityPill
                            v-if="cellData.value === 'ad'"
                            type="ad"
                            content="Ad"
                            cursor="default"
                        />
                        <EntityPill
                            v-else-if="cellData.value === 'keyword'"
                            type="keyword"
                            content="Keyword"
                            cursor="default"
                        />
                        <EntityPill v-else type="extension" content="Extension" cursor="default" />
                    </div>
                </template>
                <template #column.issues="cellData">
                    <DisapprovalStatusCell
                        :status="cellData.row.status"
                        :rows="cellData.row.reasons"
                    >
                        <NumberVue :value="cellData.value" />
                        Issue<span v-if="cellData.value > 1">s</span>
                    </DisapprovalStatusCell>
                </template>
            </oTable>

            <!-- has no disapprovals -->
            <div v-else class="no-disapprovals-tab">
                <DisapprovalsCheck />
                <Text size="f-8">All ads, keywords and extensions have been approved.</Text>
            </div>

            <!-- Breakdown Panel -->
            <BreakdownPanel
                @breakdown-requested="emit('breakdownRequested')"
                :showPanel="showBreakdownPanel"
                :closePanel="closeBreakdownPanel"
                title="Disapprovals & Restrictions"
                :width="1064"
                :loading="breakdownLoading"
            >
                <template #title>
                    <ColorTag color="blue" title="All Disapprovals" />
                </template>
                <template #content>
                    <div class="breakdown-sections">
                        <!-- Ad Disapprovals -->
                        <div class="campaign-breakdown-container">
                            <BreakdownTableHeader
                                entity-type="ad"
                                entity-label="Ad Disapprovals"
                                :currency="domainCurrency"
                                :cost="totalCost(adDisapprovalTableItems)"
                            />
                            <Spacer height="2.25rem" />
                            <oTable
                                v-if="adDisapprovalTableItems.length > 0"
                                :headers="adDisapprovalTableHeaders"
                                :items="adDisapprovalTableItems"
                                :fixed-layout="true"
                                :border-radius="20"
                                :per-page="4"
                                :persist-height="true"
                                :class="{ 'pdf-shadow-fix': isPdf }"
                            >
                                <template #header.ad>
                                    <div class="header-cell">Ad</div>
                                </template>
                                <template #column.ad="cellData">
                                    <div class="column-overlay-panel">
                                        <EntityPill
                                            type="ad"
                                            :content="`ID: ${cellData.value}`"
                                            :tooltip="true"
                                            cursor="default"
                                        />
                                    </div>
                                </template>
                                <template #column.location="cellData">
                                    <div class="location-cell">
                                        <LocationCellPopout
                                            :entity-name="`ID: ${cellData.row.ad}`"
                                            entity-type="ad"
                                            :adgroup="cellData.row.locationPopout.adgroup?.name"
                                            :campaign="cellData.row.locationPopout.campaign?.name"
                                        >
                                            <EntityPill
                                                :type="cellData.row.locationPopout.entity"
                                                :content="cellData.value"
                                                :tooltip="false"
                                                cursor="default"
                                            />
                                        </LocationCellPopout>
                                    </div>
                                </template>
                                <template #column.issues="cellData">
                                    <DisapprovalStatusCell
                                        :status="cellData.row.status"
                                        :rows="cellData.row.reasons"
                                    >
                                        <NumberVue :value="cellData.value" />
                                        Issue<span v-if="cellData.value > 1">s</span>
                                    </DisapprovalStatusCell>
                                </template>
                                <template #column.cost="cellData">
                                    <Money :currency="domainCurrency" :value="cellData.value" />
                                </template>
                                <template #column.impressions="cellData">
                                    <NumberVue :value="cellData.value" />
                                </template>
                            </oTable>
                            <NoDisapprovalsEmptyState v-else disapproval-type="ads" />
                        </div>

                        <!-- Keyword Disapprovals -->
                        <div class="campaign-breakdown-container">
                            <BreakdownTableHeader
                                entity-type="keyword"
                                entity-label="Keyword Disapprovals"
                                :currency="domainCurrency"
                                :cost="totalCost(keywordDisapprovalTableItems)"
                            />
                            <Spacer height="2.25rem" />
                            <oTable
                                v-if="keywordDisapprovalTableItems.length > 0"
                                :headers="keywordDisapprovalTableHeaders"
                                :items="keywordDisapprovalTableItems"
                                :fixed-layout="true"
                                :border-radius="20"
                                :per-page="4"
                                :persist-height="true"
                                :class="{ 'pdf-shadow-fix': isPdf }"
                            >
                                <template #header.keyword>
                                    <div class="header-cell">Keyword</div>
                                </template>
                                <template #column.keyword="cellData">
                                    <div class="column-overlay-panel">
                                        <EntityPill
                                            type="keyword"
                                            :content="cellData.value"
                                            :tooltip="true"
                                            cursor="default"
                                        />
                                    </div>
                                </template>
                                <template #column.location="cellData">
                                    <div class="location-cell">
                                        <LocationCellPopout
                                            :entity-name="cellData.row.keyword"
                                            entity-type="keyword"
                                            :adgroup="cellData.row.locationPopout.adgroup?.name"
                                            :campaign="cellData.row.locationPopout.campaign?.name"
                                        >
                                            <EntityPill
                                                :type="cellData.row.locationPopout.entity"
                                                :content="cellData.value"
                                                :tooltip="false"
                                                cursor="default"
                                            />
                                        </LocationCellPopout>
                                    </div>
                                </template>
                                <template #column.issues="cellData">
                                    <DisapprovalStatusCell
                                        :status="cellData.row.status"
                                        :rows="cellData.row.reasons"
                                    >
                                        <NumberVue :value="cellData.value" />
                                        Issue<span v-if="cellData.value > 1">s</span>
                                    </DisapprovalStatusCell>
                                </template>
                                <template #column.cost="cellData">
                                    <Money :currency="domainCurrency" :value="cellData.value" />
                                </template>
                                <template #column.impressions="cellData">
                                    <NumberVue :value="cellData.value" />
                                </template>
                            </oTable>
                            <NoDisapprovalsEmptyState v-else disapproval-type="keywords" />
                        </div>

                        <!-- Extension Disapprovals -->
                        <div
                            v-if="extensionsByTypeTable.length > 0"
                            v-for="table in extensionsByTypeTable"
                            class="campaign-breakdown-container"
                        >
                            <BreakdownTableHeader
                                entity-type="extension"
                                :entity-label="`${titleCase(table.extensionType)} Disapprovals`"
                                :currency="domainCurrency"
                                :cost="totalCost(table.disapprovalTableItems)"
                            />
                            <Spacer height="2.25rem" />
                            <oTable
                                v-if="table.disapprovalTableItems.length > 0"
                                :headers="extensionDisapprovalTableHeaders"
                                :items="table.disapprovalTableItems"
                                :fixed-layout="true"
                                :border-radius="20"
                                :per-page="4"
                                :persist-height="true"
                                :class="{ 'pdf-shadow-fix': isPdf }"
                            >
                                <template #header.extension>
                                    <div class="header-cell">
                                        {{ titleCase(table.extensionType) }}
                                    </div>
                                </template>
                                <template #column.extension="cellData">
                                    <div class="column-overlay-panel">
                                        <EntityPill
                                            type="extension"
                                            :content="cellData.value"
                                            :tooltip="true"
                                            cursor="default"
                                        />
                                    </div>
                                </template>
                                <template #column.location="cellData">
                                    <div class="location-cell">
                                        <LocationCellPopout
                                            :entity-name="cellData.row.extension"
                                            entity-type="extension"
                                            :adgroup="cellData.row.locationPopout.adgroup?.name"
                                            :campaign="cellData.row.locationPopout.campaign?.name"
                                        >
                                            <EntityPill
                                                :type="cellData.row.locationPopout.entity"
                                                :content="cellData.value"
                                                :tooltip="false"
                                                cursor="default"
                                            />
                                        </LocationCellPopout>
                                    </div>
                                </template>
                                <template #column.issues="cellData">
                                    <DisapprovalStatusCell
                                        :status="cellData.row.status"
                                        :rows="cellData.row.reasons"
                                    >
                                        <NumberVue :value="cellData.value" />
                                        Issue<span v-if="cellData.value > 1">s</span>
                                    </DisapprovalStatusCell>
                                </template>
                                <template #column.cost="cellData">
                                    <Money :currency="domainCurrency" :value="cellData.value" />
                                </template>
                                <template #column.impressions="cellData">
                                    <NumberVue :value="cellData.value" />
                                </template>
                            </oTable>
                        </div>
                        <div v-else class="campaign-breakdown-container">
                            <BreakdownTableHeader
                                entity-type="extension"
                                entity-label="Extension Disapprovals"
                                :currency="domainCurrency"
                                :cost="null"
                            />
                            <Spacer height="2.25rem" />
                            <NoDisapprovalsEmptyState disapproval-type="extensions" />
                        </div>
                    </div>
                </template>
                <template #footer>
                    <oButton
                        @clicked="
                            downloadCsv({
                                dataSet: 'disapprovals',
                                items: csvDownload,
                                options: {
                                    initialColumnHeaders: [
                                        'Type',
                                        'Name',
                                        'ID',
                                        'Location',
                                        'Ad Group',
                                        'Campaign',
                                        'Status',
                                        'Issues',
                                        'Cost',
                                        'Impressions',
                                    ],
                                },
                            })
                        "
                        size="extra-large"
                    >
                        Download CSV
                    </oButton>
                </template>
            </BreakdownPanel>
        </template>
    </ScorecardSection>
</template>

<script lang="ts" setup>
import { defineComponent, computed, PropType, ref } from 'vue'
import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'
import orderBy from 'lodash-es/orderBy'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { downloadCsv } from '@/lib/globalUtils'
import { getSectionCopy, formatBreakdown } from './utils'

import ScorecardSection from './ScorecardSection.vue'
import DisapprovalsCheck from './DisapprovalsCheck.vue'
import SubSectionHeader from './SubSectionHeader.vue'
import DisapprovalStatusBlock from './DisapprovalStatusBlock.vue'
import DisapprovalStatusCell from './DisapprovalStatusCell.vue'
import BreakdownTableHeader from './BreakdownTableHeader.vue'
import LocationCellPopout from './LocationCellPopout.vue'
import NoDisapprovalsEmptyState from './NoDisapprovalsEmptyState.vue'
import BreakdownPanel from './BreakdownPanel.vue'

import { RsaWriter, Scorecard, Account } from '@opteo/types'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Number as NumberVue,
    oButton,
    ColorTag,
    EntityPill,
} from '@opteo/components-next'
import { Endpoint } from '@/composition/api/endpoints'
import { useAPI } from '@/composition/api/useAPI'
import keyBy from 'lodash-es/keyBy'

const props = defineProps<{
    score: number
    invalid: boolean
    domainName: string
    domainId: number | Account.ID
    domainCurrency: string
    isLoading: boolean
    mode: 'live' | 'pdf' | 'app'
    details: Scorecard.DisapprovalsScoreSection['details']
    breakdown?: Scorecard.DisapprovalsScoreSection['breakdown']
    breakdownLoading?: boolean
}>()

const emit = defineEmits<{
    scoreHistoryButtonClicked: []
    breakdownRequested: []
}>()

const isPdf = props.mode === 'pdf'
const { aboveiPadLandscape } = useMediaQuery()
const sectionName = Scorecard.SectionNames.disapprovals
const router = useRouter()

const domainId = props.domainId
const isValidDomainId = computed(() => !!domainId && typeof domainId === 'number')
const shouldMakeAPICall = computed(() => isValidDomainId.value && props.mode === 'app')

const { data: availableRsas } = useAPI<RsaWriter.GadsRsa[]>(Endpoint.GetRsas, {
    waitFor: () => shouldMakeAPICall.value,
    uniqueId: () => domainId?.toString(),
    body: { domainId: domainId },
})

const textOptions = {
    0: [
        `Your account has a number of outstanding disapprovals that require attention. Disapprovals can occur for many reasons, but are often resolved with a few simple changes. We recommend handling disapprovals promptly, because repeat offences and long standing disapprovals can lead to account suspension.`,
    ],
    100: [
        `All of your ads, keywords, and extensions have been approved. Disapprovals can occur for a number of reasons, but are often resolved with a few simple changes. We recommend handling disapprovals promptly, because repeat offences and long standing disapprovals can lead to account suspension.`,
    ],
}

const sectionCopy = computed(() => {
    return getSectionCopy(textOptions, props.score ?? 0)
})

// Approval Summary
const totalNumberOfAds = computed(() => {
    return props.details?.summary.ads.total
})
const totalNumberOfAdsDisapproved = computed(() => {
    return props.details?.summary.ads.disapproved
})
const totalNumberOfKeywords = computed(() => {
    return props.details?.summary.keywords.total
})
const totalNumberOfKeywordsDisapproved = computed(() => {
    return props.details?.summary.keywords.disapproved
})
const totalNumberOfExtensions = computed(() => {
    return props.details?.summary.extensions.total
})
const totalNumberOfExtensionsDisapproved = computed(() => {
    return props.details?.summary.extensions.disapproved
})

const hasDisapprovals = computed(() => {
    return (
        totalNumberOfAdsDisapproved.value > 0 ||
        totalNumberOfKeywordsDisapproved.value > 0 ||
        totalNumberOfExtensionsDisapproved.value > 0
    )
})

// Disapprovals Tables
const disapprovalTableHeaders = [
    { key: 'location', text: 'Location', noPadding: true },
    { key: 'type', text: 'Type', noPadding: true },
    { key: 'issues', text: 'Status', noPadding: true },
]
const disapprovalTableItems = computed(() => {
    const topDisapprovalsItems = props.details?.topDisapprovals ?? []
    return topDisapprovalsItems.map(item => ({
        location:
            item.location.entity === 'adgroup'
                ? item.location.adgroup?.name
                : item.location.entity === 'campaign'
                  ? item.location.campaign?.name
                  : 'Account Level',
        locationPopout: item.location,
        type: item.type,
        status: item.disapprovalStatus,
        issues: item.disapprovalReasons.length,
        reasons: item.disapprovalReasons.map(({ topic }) => ({ topic })),
        name: item.name,
        id: item.id,
    }))
})

// Breakdown Panel
const showBreakdownPanel = ref(false)

const openBreakdownPanel = () => {
    showBreakdownPanel.value = true
}

const closeBreakdownPanel = () => {
    showBreakdownPanel.value = false
}

// Calculate total cost of items in table
function totalCost(tableItems) {
    if (tableItems.length > 0) {
        return tableItems
            ?.map(row => row.cost)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    } else return null
}

// Ad disapprovals
const adDisapprovalTableHeaders = [
    { key: 'ad', text: 'Ad', width: 240, noPadding: true, sortable: true },
    { key: 'location', text: 'Location', noPadding: true, sortable: true },
    { key: 'issues', text: 'Issues', width: 116, noPadding: true, sortable: true },
    { key: 'cost', text: 'Cost', width: 96, noPadding: true, sortable: true },
    { key: 'impressions', text: 'Impr.', width: 88, noPadding: true, sortable: true },
]

const adDisapprovalTableItems = computed(() => {
    let disapprovals = props.breakdown?.allDisapprovals ?? []
    disapprovals = orderBy(disapprovals, ['cost'], ['desc'])

    const availableRsasByAdId = keyBy(availableRsas.value ?? [], ad => ad.ad_group_ad.ad.id)

    return disapprovals
        .filter(obj => obj.type === 'ad')
        .map(item => {
            const isRsaAvailable = !!availableRsasByAdId[item.id]
            let openableRsa = item.openableRsa && isRsaAvailable

            return {
                ad: item.id,
                location: item.location.adgroup?.name,
                locationPopout: item.location,
                status: item.disapprovalStatus,
                issues: item.disapprovalReasons.length,
                reasons: item.disapprovalReasons.map(({ topic }) => ({ topic })),
                cost: item.cost,
                impressions: item.impressions,
            }
        })
})

function openAdInRsaWriter(
    campaignId: string | undefined,
    adGroupId: number | undefined,
    adId: number | undefined
) {
    const route = router.resolve({
        name: Routes.ToolkitRsaWriterAdEdit,
        params: { campaignId, adGroupId, adId },
    })

    window.open(route.href, '_blank')
}

// Keyword disapprovals
const keywordDisapprovalTableHeaders = [
    { key: 'keyword', text: 'Keyword', width: 240, noPadding: true, sortable: true },
    { key: 'location', text: 'Location', noPadding: true, sortable: true },
    { key: 'issues', text: 'Issues', width: 116, noPadding: true, sortable: true },
    { key: 'cost', text: 'Cost', width: 96, noPadding: true, sortable: true },
    { key: 'impressions', text: 'Impr.', width: 88, noPadding: true, sortable: true },
]

const keywordDisapprovalTableItems = computed(() =>
    (orderBy(props.breakdown?.allDisapprovals, ['cost'], ['desc']) ?? [])
        .filter(obj => obj.type === 'keyword')
        .map(item => ({
            keyword: item.name,
            location: item.location.adgroup?.name,
            locationPopout: item.location,
            status: item.disapprovalStatus,
            issues: item.disapprovalReasons.length,
            reasons: item.disapprovalReasons.map(({ topic }) => ({ topic })),
            cost: item.cost,
            impressions: item.impressions,
        }))
)

// Extension disapprovals
const extensionDisapprovalTableHeaders = [
    { key: 'extension', text: 'Extension', width: 240, noPadding: true, sortable: true },
    { key: 'location', text: 'Location', noPadding: true, sortable: true },
    { key: 'issues', text: 'Issues', width: 116, noPadding: true, sortable: true },
    { key: 'cost', text: 'Cost', width: 96, noPadding: true, sortable: true },
    { key: 'impressions', text: 'Impr.', width: 88, noPadding: true, sortable: true },
]
const extensionsByType = computed(() =>
    (orderBy(props.breakdown?.allDisapprovals, ['cost'], ['desc']) ?? [])
        .filter(obj => obj.type !== 'ad' && obj.type !== 'keyword')
        .reduce((result, item) => {
            const { type } = item
            result[type] = result[type] || []
            result[type].push(item)
            return result
        }, {})
)
const extensionsByTypeTable = computed(() =>
    Object.keys(extensionsByType.value).map(type => ({
        extensionType: type,
        disapprovalTableItems: extensionsByType.value[type].map(item => ({
            extension: `ID: ${item.id}`,
            location:
                item.location.entity === 'adgroup'
                    ? item.location.adgroup?.name
                    : item.location.entity === 'campaign'
                      ? item.location.campaign?.name
                      : 'Account Level',
            locationPopout: item.location,
            status: item.disapprovalStatus,
            issues: item.disapprovalReasons.length,
            reasons: item.disapprovalReasons.map(({ topic }) => ({ topic })),
            cost: item.cost,
            impressions: item.impressions,
        })),
    }))
)

const csvDownload = computed(() => {
    return (
        orderBy(props.breakdown?.allDisapprovals, ['type', 'impressions'], ['asc', 'desc']).map(
            item => {
                return {
                    type: item.type,
                    name: item.name ?? '',
                    id: item.id,
                    location: item.location.entity,
                    adGroup: item.location.adgroup?.name ?? '',
                    campaign: item.location.campaign?.name ?? '',
                    status: item.disapprovalStatus,
                    issues: item.disapprovalReasons
                        ?.map(reason => {
                            return titleCase(reason.topic)
                        })
                        .toString(),
                    cost: item.cost,
                    impressions: item.impressions,
                }
            }
        ) ?? []
    )
})

function titleCase(str) {
    str = str.toLowerCase().split('_')
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.no-disapprovals-tab {
    @include container;
    @include flex;
    @include items-center;
    @include ph-24;
    @include pv-20;
    @include br-20;
    gap: 0.75rem;
}

.breakdown-sections {
    @include flex;
    flex-direction: column;
    gap: 2.25rem;
}
.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}

.column-overlay {
    width: 100%;
    min-width: 12.5rem;
    max-width: 13.5rem;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
    cursor: default;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.column-overlay-panel {
    width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
    cursor: default;
}
.column-overlay-panel::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 3rem;
    bottom: 0;
}

.type-cell {
    @include pr-24;
    cursor: default;
}

.header-cell {
    @include pl-24;
}

.location-cell {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include flex;
    @include items-center;
    cursor: default;
}
.location-cell::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.location-cell :deep(.popout-wrapper) {
    @include inline-flex;
}

:deep(.improvement-note) {
    @include br-20;
}

.open-ad-button :deep(.o-button.small) {
    @include br-12;
    padding: 15px 15px 14px 15px;
    height: 40px;
}

@media screen and (max-width: $mq-767-max) {
    .header-cell {
        padding-left: unset;
    }
    .column-overlay {
        height: unset;
        padding-left: unset;
        overflow: unset;
        @include w-100;
        min-width: unset;
    }
    .column-overlay::after {
        display: none;
    }
    :deep(.popout-wrapper) {
        @include w-100;
    }
}
</style>
