import { computed, onMounted, ref } from 'vue'
import { Improvement, ContentExclusion } from '@opteo/types'
import { API_URL } from '@/lib/env'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, useRoas } from '@opteo/components-next'

import defaultAppIcon from '@/assets/img/appReplacement.svg'
import { formatEntityPillLink } from '@/lib/globalUtils'
import { extractIdFromResourceName } from '@/lib/globalUtils/extractIdFromResourceName'

export function useExcludeContent() {
    const { improvement, lastUpdated, title } = useImprovement<
        ContentExclusion.ImpTypes.Body,
        typeof Improvement.RecAction.ExcludeContent
    >()
    const { body } = checkImprovement(improvement)

    const lookbackWindow = 365

    const pushMessages = [
        'Connecting to Google Ads..',
        'Adding placement to exclusion list..',
        'Confirming changes..',
        'Placement excluded successfully.',
    ]

    const placementUrl = `https://${body.placement.target_url}`
    const placementPreviewUrl = `${API_URL}/render/website?website=${body.placement.target_url}`
    const campaignPillList = body.campaigns_queried.map(campaign => {
        return formatEntityPillLink({
            entityPillData: {
                type: 'campaign',
                content: campaign.name,
            },
            deepLinkParams: {
                campaignId: Number(extractIdFromResourceName(campaign.resource_name, 'campaign')),
            },
        })
    })

    // Account-level exclusions: Single mobile app, website placement, YouTube channel
    // all-supported-campaigns exclusions: All mobile apps, Gmail, all YouTube
    const isAccountLevelExclusion = body.exclusion_level === ContentExclusion.ExclusionLevel.ACCOUNT

    const performanceMode = body.performance_mode

    const hasConversions =
        performanceMode === ContentExclusion.ContentPerformanceMode.Cpa
            ? body.placement_metrics.combined_conv > 0
            : body.placement_metrics.combined_conv_value > 0

    const environmentCpa = body.environment_metrics.cost / body.environment_metrics.combined_conv
    const placementCpa = body.placement_metrics.cost / body.placement_metrics.combined_conv

    const conversionValue = useDomainMoney({
        value: body.placement_metrics.combined_conv_value,
    }).value.displayValue.value

    const conversions = body.placement_metrics.combined_conv

    const environmentRoas = body.environment_metrics.cost
        ? body.environment_metrics.combined_conv_value / body.environment_metrics.cost
        : 0

    const placementRoas = body.placement_metrics.cost
        ? body.placement_metrics.combined_conv_value / body.placement_metrics.cost
        : 0

    onMounted(async () => {
        if (
            body.exclusion_type === ContentExclusion.ExclusionType.MOBILE_APP ||
            body.exclusion_type === ContentExclusion.ExclusionType.MOBILE_ALL
        ) {
            for (const app of mobileApps.value) {
                const appId = app.placement.substring(2, 9999)
                const { url, platform } = await getAppMetadata(appId)

                app.platform = platform
                app.image = `https://${url}`
            }
        }
    })

    const mobileApps = ref<
        {
            name: string
            image: string
            placeholder: string
            placement: string
            platform?: string
        }[]
    >([])

    const getAppMetadata = async (
        id: string
    ): Promise<{ url: string; name: string; platform: string }> => {
        return authRequest<{ name: string; url: string; platform: string }>(
            Endpoint.GetMobileAppMetadata,
            { body: { appId: id } }
        )
    }

    const exclusionType = body.exclusion_type

    const isPlural =
        exclusionType === ContentExclusion.ExclusionType.MOBILE_ALL ||
        exclusionType === ContentExclusion.ExclusionType.YOUTUBE_ALL ||
        exclusionType === ContentExclusion.ExclusionType.GMAIL

    const placementNames = {
        [ContentExclusion.ExclusionType.WEBSITE]: body.placement.display_name,
        [ContentExclusion.ExclusionType.YOUTUBE_CHANNEL]: body.placement.display_name,
        [ContentExclusion.ExclusionType.MOBILE_APP]: body.placement.display_name,
        [ContentExclusion.ExclusionType.GMAIL]: 'Gmail',
        [ContentExclusion.ExclusionType.YOUTUBE_ALL]: 'YouTube',
        [ContentExclusion.ExclusionType.MOBILE_ALL]: body.placement.display_name,
    }

    const placementName = placementNames[body.exclusion_type]

    if (body.exclusion_type === ContentExclusion.ExclusionType.MOBILE_APP) {
        mobileApps.value.push({
            name: placementName,
            image: defaultAppIcon,
            placeholder: defaultAppIcon,
            placement: body.placement.placement,
        })
    }

    if (body.exclusion_type === ContentExclusion.ExclusionType.MOBILE_ALL) {
        mobileApps.value =
            body.top_mobile_apps?.map(app => {
                return {
                    name: app.name,
                    image: defaultAppIcon,
                    placeholder: defaultAppIcon,
                    placement: app.app_id,
                }
            }) ?? []
    }

    const t = {
        [ContentExclusion.ExclusionType.WEBSITE]: 'website',
        [ContentExclusion.ExclusionType.GMAIL]: 'Gmail',
        [ContentExclusion.ExclusionType.YOUTUBE_ALL]: 'YouTube',
        [ContentExclusion.ExclusionType.YOUTUBE_CHANNEL]: 'YouTube',
        [ContentExclusion.ExclusionType.MOBILE_APP]: 'mobile app',
        [ContentExclusion.ExclusionType.MOBILE_ALL]: 'mobile app',
    }
    const genericPlacementCopy = t[exclusionType]

    const u = {
        [ContentExclusion.ExclusionType.WEBSITE]: 'a website',
        [ContentExclusion.ExclusionType.GMAIL]: 'Gmail ad',
        [ContentExclusion.ExclusionType.YOUTUBE_ALL]: 'YouTube ad',
        [ContentExclusion.ExclusionType.YOUTUBE_CHANNEL]: 'a YouTube',
        [ContentExclusion.ExclusionType.MOBILE_APP]: 'an app',
        [ContentExclusion.ExclusionType.MOBILE_ALL]: 'mobile app',
    }
    const genericPlacementCopyParagraph = u[exclusionType]

    const singleEnvironment = {
        [ContentExclusion.ExclusionType.WEBSITE]: 'website placements',
        [ContentExclusion.ExclusionType.YOUTUBE_CHANNEL]: 'YouTube channels',
        [ContentExclusion.ExclusionType.MOBILE_APP]: 'mobile apps',
    }
    const singleEnvironmentCopy =
        singleEnvironment[
            exclusionType as
                | ContentExclusion.ExclusionType.WEBSITE
                | ContentExclusion.ExclusionType.YOUTUBE_CHANNEL
                | ContentExclusion.ExclusionType.MOBILE_APP
        ]

    const singleEnvironmentStat = {
        [ContentExclusion.ExclusionType.WEBSITE]: 'Website',
        [ContentExclusion.ExclusionType.YOUTUBE_CHANNEL]: 'YouTube',
        [ContentExclusion.ExclusionType.MOBILE_APP]: 'Mobile App',
    }
    const singleEnvironmentStatCopy =
        singleEnvironmentStat[
            exclusionType as
                | ContentExclusion.ExclusionType.WEBSITE
                | ContentExclusion.ExclusionType.YOUTUBE_CHANNEL
                | ContentExclusion.ExclusionType.MOBILE_APP
        ]

    const tperformanceMode = {
        [ContentExclusion.ContentPerformanceMode.Cpa]: 'CPA',
        [ContentExclusion.ContentPerformanceMode.Roas]: 'ROAS',
    }
    const performanceModeCopy = tperformanceMode[performanceMode]

    const scatterPointChartItems =
        body.scatter_plot_points &&
        body.scatter_plot_points.map(point => {
            if (performanceMode === ContentExclusion.ContentPerformanceMode.Cpa) {
                return {
                    x: point.all_conversions,
                    y: point.cost,
                    label: point.label,
                    highlighted: point.is_current_placement,
                }
            } else {
                return {
                    x: point.cost,
                    y: point.all_conversions_value,
                    label: point.label,
                    highlighted: point.is_current_placement,
                }
            }
        })

    const cpaDiff = 1 - environmentCpa / placementCpa
    const roasDiff = 1 - placementRoas / environmentRoas

    const performanceDiff =
        performanceMode === ContentExclusion.ContentPerformanceMode.Cpa ? cpaDiff : roasDiff

    const stats = computed(() => {
        if (performanceMode === ContentExclusion.ContentPerformanceMode.Cpa) {
            return [
                {
                    // Format cost
                    key: 'cost',
                    value: useDomainMoney({
                        value: body.placement_metrics.cost,
                    }).value.displayValue.value,
                    title: 'Cost',
                },
                {
                    key: 'conversions',
                    value: useNumber({ value: body.placement_metrics.combined_conv }).displayValue
                        .value,
                    title:
                        body.placement_metrics.combined_conv === 1 ? 'Conversion' : 'Conversions',
                },
                {
                    key: 'placementCpa',
                    value: useDomainMoney({
                        value: placementCpa,
                    }).value.displayValue.value,
                    title: 'Placement CPA',
                },
                {
                    key: 'envCpa',
                    value: useDomainMoney({
                        value: environmentCpa,
                    }).value.displayValue.value,
                    title: isAccountLevelExclusion
                        ? `Avg. ${singleEnvironmentStatCopy} CPA`
                        : 'Avg. Display Campaign CPA',
                },
            ]
        } else if (performanceMode === ContentExclusion.ContentPerformanceMode.Roas) {
            return [
                {
                    // Format cost
                    key: 'cost',
                    value: useDomainMoney({
                        value: body.placement_metrics.cost,
                    }).value.displayValue.value,
                    title: 'Cost',
                },
                {
                    key: 'conversionsValue',
                    value: useDomainMoney({
                        value: body.placement_metrics.combined_conv_value,
                    }).value.displayValue.value,
                    title: 'Conversion Value',
                },
                {
                    key: 'placementRoas',
                    value: useRoas({
                        value: placementRoas,
                    }).displayValue.value,
                    title: 'Placement ROAS',
                },
                {
                    key: 'envRoas',
                    value: useRoas({
                        value: environmentRoas,
                    }).displayValue.value,
                    title: isAccountLevelExclusion
                        ? `Avg. ${singleEnvironmentStatCopy} ROAS`
                        : 'Avg. Display Campaign ROAS',
                },
            ]
        }
    })

    const onPush: OnPushHandler<ContentExclusion.ImpTypes.ExtraDetails> = () => {
        return {
            valid: true,
            pushedData: {
                // shared_set_id: selectedSharedSet.value,
            },
        }
    }

    const pushActionText = ref(isPlural ? 'Exclude Placements' : 'Exclude Placement')

    const youtubeChannelMetadata = {
        ...body.placement.youtube_channel_metadata,
        channelUrl: `https://${body.placement.target_url}`,
        description: body.placement.youtube_channel_metadata?.description,
    }

    return {
        pushMessages,
        pushActionText,
        onPush,
        lastUpdated,
        campaignPillList,
        stats,
        title,
        lookbackWindow,

        placementPreviewUrl,
        isAccountLevelExclusion,
        hasConversions,
        performanceModeCopy,
        exclusionType,
        ExclusionTypeEnum: ContentExclusion.ExclusionType,
        placementName,
        placementMetrics: body.placement_metrics,
        scatterPointChartItems,
        performanceDiff,
        genericPlacementCopy,
        genericPlacementCopyParagraph,
        mobileApps,
        youtubeChannelMetadata,

        // perf
        performanceMode,
        PerformanceModeEnum: ContentExclusion.ContentPerformanceMode,
        environmentCpa,
        placementCpa,
        environmentRoas,
        placementRoas,
        conversionValue,
        conversions,
    }
}
