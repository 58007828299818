// global inports
import { computed, provide, inject, ref, Ref } from 'vue'
import orderBy from 'lodash-es/orderBy'
import sumBy from 'lodash-es/sumBy'
import round from 'lodash-es/round'
import cloneDeep from 'lodash-es/cloneDeep'
import differenceInDays from 'date-fns/differenceInDays'
import { showAsyncPush } from '@opteo/components-next'

// local imports
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { enums } from '@opteo/types/gads'
import { SmartBiddingExperiments, Targets } from '@opteo/types'
import { useRoute, useRouter } from 'vue-router'
import { useDomain, getDomainInitials } from '@/composition/domain/useDomain'
import { Routes } from '@/router/routes'
import { isOdd } from '@/lib/globalUtils'
import { OpteoExperimentStatus } from '@opteo/types/smart-bidding-experiments'
import { delay } from '@opteo/promise'

// maps bidding strategy enums to their respective copy values
export const biddingStrategyTypeMap: {
    [BiddingStrategyType in enums.BiddingStrategyType]?: string
} = {
    [enums.BiddingStrategyType.COMMISSION]: 'Commission',
    [enums.BiddingStrategyType.ENHANCED_CPC]: 'Enhanced CPC',
    [enums.BiddingStrategyType.MANUAL_CPA]: 'Manual CPA',
    [enums.BiddingStrategyType.MANUAL_CPC]: 'Manual CPC',
    [enums.BiddingStrategyType.MANUAL_CPM]: 'Manual CPM',
    [enums.BiddingStrategyType.MANUAL_CPV]: 'Manual CPV',
    [enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS]: 'Max. Conv.',
    [enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE]: 'Max. Conv. Value',
    [enums.BiddingStrategyType.PAGE_ONE_PROMOTED]: 'Page One Promo.',
    [enums.BiddingStrategyType.PERCENT_CPC]: 'Percent CPC',
    [enums.BiddingStrategyType.TARGET_CPA]: 'Target CPA',
    [enums.BiddingStrategyType.TARGET_CPM]: 'Target CPM',
    [enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE]: 'Target Imp. Share',
    [enums.BiddingStrategyType.TARGET_OUTRANK_SHARE]: 'Target Out. Share',
    [enums.BiddingStrategyType.TARGET_ROAS]: 'Target ROAS',
    [enums.BiddingStrategyType.TARGET_SPEND]: 'Max. Clicks',
}

export const allBiddingStrategiesSelectItems = Object.entries(biddingStrategyTypeMap).map(
    ([value, label]) => {
        return { value: +value, label }
    }
)

export const searchBiddingStrategiesSelectItems = [
    {
        value: enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE],
    },
    {
        value: enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE],
    },
    {
        value: enums.BiddingStrategyType.TARGET_ROAS,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_ROAS],
    },
    {
        value: enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS],
    },
    {
        value: enums.BiddingStrategyType.TARGET_CPA,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_CPA],
    },
    {
        value: enums.BiddingStrategyType.TARGET_SPEND,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_SPEND],
    },
    {
        value: enums.BiddingStrategyType.MANUAL_CPC,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MANUAL_CPC],
    },
]

export const displayBiddingStrategiesSelectItems = [
    {
        value: enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE],
    },
    {
        value: enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS],
    },
    {
        value: enums.BiddingStrategyType.TARGET_SPEND,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_SPEND],
    },
    {
        value: enums.BiddingStrategyType.TARGET_ROAS,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_ROAS],
    },
    {
        value: enums.BiddingStrategyType.TARGET_CPA,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_CPA],
    },
    {
        value: enums.BiddingStrategyType.MANUAL_CPC,
        label: biddingStrategyTypeMap[enums.BiddingStrategyType.MANUAL_CPC],
    },
]

interface ExperimentCreationFormBase {
    experimentName: string // Must have length, max length 244 characters
    experimentDescription: string // Max length 2048 characters
    experimentBaseBudgetAllocation: number // Must be greater than 0 and less than 100
    experimentDurationDays: number // Must be greater than 0
}

interface SearchExperimentCreationForm {
    experimentBiddingStrategy: enums.BiddingStrategyType | undefined
    targetImpressionShareLocation: enums.TargetImpressionShareLocation
    targetImpressionSharePercentage: number | string // Must be greater than 0, max of 1
    targetImpressionShareMaxCpcBidLimit: number | string // Must be greater than 0
    maximiseConversionValueIsTargetRoas: boolean
    maximiseConversionValueTargetRoas: number | string // Must be greater than 0, max of 1000
    maximiseConversionValueMaxCpcBidLimit: number | string // Must be greater than 0
    maximiseConversionsIsTargetCpa: boolean
    maximiseConversionsTargetCpa: number | string // Must be greater than 0
    maximiseConversionsMaxCpcBidLimit: number | string // Must be greater than 0
    targetSpendSetMaxCpc: boolean
    targetSpendMaxCpc: number | string // Must be greater than 0
    manualCpcUseEnhancedCpc: boolean
    manualCpcMaxAdGroupCpcBidLimit: number | string // Must be greater than 0
}

interface DisplayExperimentCreationForm {
    experimentBiddingStrategy: enums.BiddingStrategyType | undefined
    targetSpendSetMaxCpc: boolean
    targetSpendMaxCpc: number | string // Must be greater than 0
    targetRoas: number | string // Must be greater than 0, max of 1000
    targetCpa: number | string // Must be greater than 0
    manualCpcUseEnhancedCpc: boolean
    manualCpcMaxAdGroupCpcBidLimit: number | string // Must be greater than 0
}

export const PerformanceMetricTypes = {
    cpa: 'cpa',
    roas: 'roas',
    targetImpressionShare: 'targetImpressionShare',
} as const

export interface CampaignTableItem {
    id: string
    campaign: string
    cost: number
    conversions: number
    impressions: number
    convValue: number
    cpa: number
    roas: number
    strategy: string
    rawStrategy: enums.BiddingStrategyType
    advertisingChannelType: enums.AdvertisingChannelType
    selected: boolean
}

export type CampaignPerformanceData = SmartBiddingExperiments.GetAccountExperimentDataResponse[
    | 'experimentTotalData'
    | 'baseTotalData']

export function provideExperiments() {
    // Domain
    const { domainColour, domainName, domainId, currencySymbol } = useDomain()
    const domainInitials = computed(() => getDomainInitials(domainName.value as string))

    const route = useRoute()

    const {
        data: campaignsAndExperiments,
        loading,
        progressStep,
        mutate: mutateGetCampaignsAndExperiments,
    } = useAPI<SmartBiddingExperiments.GetCampaignsAndExperimentsResponse>(
        Endpoint.GetCampaignsAndExperiments,
        { uniqueId: () => domainId.value, pollForProgress: true }
    )

    const {
        data: experimentData,
        loading: experimentDataLoading,
        mutate: mutateGetAccountExperimentData,
    } = useAPI<SmartBiddingExperiments.GetAccountExperimentDataResponse[]>(
        Endpoint.GetAccountExperimentData,
        { uniqueId: () => domainId.value }
    )

    const experimentRecommendations = computed(() =>
        campaignsAndExperiments.value?.experimentRecommendations.map(recommendation => {
            return {
                ...recommendation,
                bidStrategy: biddingStrategyTypeMap[recommendation.biddingStrategyType],
                recommendedBidStrategy:
                    biddingStrategyTypeMap[recommendation.recommendedBidStrategy],
                rawRecommendedBidStrategy: recommendation.recommendedBidStrategy,
            }
        })
    )

    const experimentCarouselSlides = computed(() => {
        const carouselEmptySlide = { emptyState: true }

        return experimentRecommendations.value && isOdd(experimentRecommendations.value.length)
            ? [...experimentRecommendations.value, carouselEmptySlide]
            : experimentRecommendations.value
    })

    // Active component
    const activeExperiments = computed(() =>
        orderBy(
            experimentData.value?.filter(recommendation => {
                return (
                    recommendation.status !==
                        SmartBiddingExperiments.OpteoExperimentStatus.COMPLETED &&
                    recommendation.status !==
                        SmartBiddingExperiments.OpteoExperimentStatus.REMOVING &&
                    recommendation.status !==
                        SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
                )
            }),
            i => {
                return getPercentComplete({
                    start: i.creationDate,
                    end: i.actualEndDate,
                })
            },
            ['desc']
        )
    )
    const activeExperimentsCount = computed(() => activeExperiments.value?.length)

    // Completed component
    const completedExperiments = computed(() =>
        orderBy(
            experimentData.value?.filter(recommendation => {
                return (
                    recommendation.status ===
                        SmartBiddingExperiments.OpteoExperimentStatus.COMPLETED ||
                    recommendation.status ===
                        SmartBiddingExperiments.OpteoExperimentStatus.REMOVING ||
                    recommendation.status ===
                        SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
                )
            }),
            i => new Date(i.actualEndDate).getTime(),
            ['desc']
        )
    )

    const completedExperimentsCount = computed(() => completedExperiments.value?.length)

    const latestCreatedExperimentId = ref('')

    const isNewExperiment = (id: string) => id === latestCreatedExperimentId.value

    /**
     * @description There are 5 different cases:
     * - experimentBiddingStrategy bidding strategy type is TARGET_IMPRESSION_SHARE
     * - experimentBiddingStrategy bidding strategy type is TARGET_ROAS | MAXIMIZE_CONVERSION_VALUE
     * - experimentBiddingStrategy bidding strategy type is TARGET_CPA | MAXIMIZE_CONVERSIONS
     * - baseBiddingStrategy bidding strategy type is TARGET_ROAS | MAXIMIZE_CONVERSIONS_VALUE
     * - everything else
     * @returns void
     */
    const getDataSetup = ({
        experimentBiddingStrategy,
        baseBiddingStrategy,
    }: {
        experimentBiddingStrategy: enums.BiddingStrategyType | undefined
        baseBiddingStrategy: enums.BiddingStrategyType | undefined
    }) => {
        if (experimentBiddingStrategy === undefined || baseBiddingStrategy === undefined) {
            return PerformanceMetricTypes.cpa
        }

        /** Using only "pure" CPA bidding strategy types as opposed to the CPAs from SmartBiddingExperiments types */
        const pureCpaBiddingStrategies = [
            enums.BiddingStrategyType.TARGET_CPA,
            enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS,
        ]

        if (experimentBiddingStrategy === enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE) {
            return PerformanceMetricTypes.targetImpressionShare
        }

        if (SmartBiddingExperiments.roasBiddingStrategies.includes(experimentBiddingStrategy)) {
            return PerformanceMetricTypes.roas
        }

        if (pureCpaBiddingStrategies.includes(experimentBiddingStrategy)) {
            return PerformanceMetricTypes.cpa
        }

        if (SmartBiddingExperiments.roasBiddingStrategies.includes(baseBiddingStrategy)) {
            return PerformanceMetricTypes.roas
        }

        return PerformanceMetricTypes.cpa
    }

    const bothArmsHaveCost = ({
        experimentCost,
        baseCost,
    }: {
        experimentCost: number
        baseCost: number
    }) => {
        return experimentCost > 0 && baseCost > 0
    }

    const bothArmsHaveConversions = ({
        experimentAllConversions,
        baseAllConversions,
    }: {
        experimentAllConversions: number
        baseAllConversions: number
    }) => {
        return experimentAllConversions > 0 && baseAllConversions > 0
    }

    const getHasEnoughData = ({
        experimentTotalData,
        baseTotalData,
        experimentBiddingStrategy,
        baseBiddingStrategy,
    }: {
        experimentTotalData: CampaignPerformanceData
        baseTotalData: CampaignPerformanceData
        experimentBiddingStrategy: enums.BiddingStrategyType
        baseBiddingStrategy: enums.BiddingStrategyType
    }) => {
        const dataSetup = getDataSetup({ experimentBiddingStrategy, baseBiddingStrategy })

        if (dataSetup === PerformanceMetricTypes.cpa) {
            const { allConversions: experimentAllConversions } = experimentTotalData
            const { allConversions: baseAllConversions } = baseTotalData

            return bothArmsHaveConversions({ experimentAllConversions, baseAllConversions })
        } else {
            const { cost: experimentCost } = experimentTotalData
            const { cost: baseCost } = baseTotalData

            return bothArmsHaveCost({ experimentCost, baseCost })
        }
    }

    // Empty states
    const hasEligibleCampaigns = computed(() => eligibleCampaignsTableItems.value?.length)
    const hasRecommendations = computed(() => experimentRecommendations.value?.length)
    const hasActiveExperiments = computed(() => !!activeExperimentsCount.value)

    const getCampaignExperimentEntityPill = function (
        campaignExperimentPairs: SmartBiddingExperiments.CampaignExperimentPair[]
    ) {
        return campaignExperimentPairs.map(experiment => {
            return {
                campaignName: experiment.baseCampaignMetrics.name,
            }
        })
    }

    const getRelevantPerformanceMetricType = function (biddingStrategy: enums.BiddingStrategyType) {
        if (SmartBiddingExperiments.cpaBiddingStrategies.includes(biddingStrategy)) {
            return PerformanceMetricTypes.cpa
        }

        if (SmartBiddingExperiments.roasBiddingStrategies.includes(biddingStrategy)) {
            return PerformanceMetricTypes.roas
        }

        if (biddingStrategy === enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE) {
            return PerformanceMetricTypes.targetImpressionShare
        }
    }

    // Dates utils
    const getPercentComplete = function ({ start, end }: { start: string; end: string }) {
        const currentDate = new Date()
        let startDate = new Date(start)
        const endDate = new Date(end)
        const totalExperimentDays = differenceInDays(startDate, endDate)

        return totalExperimentDays && totalExperimentDays !== 0
            ? Math.round((differenceInDays(startDate, currentDate) / totalExperimentDays) * 100) /
                  100
            : 1
    }

    const getDaysLeft = function (end: string) {
        const currentDate = new Date()
        const endDate = new Date(end)
        return Math.max(differenceInDays(endDate, currentDate), 0)
    }

    const isExperimentEndEarly = function (end: string, endActual: string) {
        const actualEndDate = new Date(endActual)
        const endDate = new Date(end)
        return Math.max(differenceInDays(endDate, actualEndDate), 0) > 0
    }

    const getTotalDays = function (start: string, end: string) {
        const startDate = new Date(start)
        const endDate = new Date(end)
        return differenceInDays(endDate, startDate)
    }

    const getIsExperimentDurationComplete = (end: string) => {
        const daysLeft = getDaysLeft(end)

        return daysLeft === 0
    }

    // Confidence utils
    const getIsConfidenceAboveNinetyFivePercent = (confidenceLevel: number | null) => {
        return confidenceLevel && confidenceLevel > 0.95
    }

    const eligibleCampaignsTableItems = computed(() =>
        campaignsAndExperiments.value?.eligibleCampaigns.map(campaign => {
            return {
                id: String(campaign.campaignId),
                campaign: campaign.campaignName,
                cost: campaign.metrics.thirtyDayCost,
                conversions: campaign.metrics.thirtyDayConversions,
                impressions: campaign.metrics.thirtyDayImpressions,
                convValue: campaign.metrics.thirtyDayConversionsValue,
                cpa: campaign.metrics.thirtyDayCPA || 0,
                roas: campaign.metrics.thirtyDayROAS,
                strategy: biddingStrategyTypeMap[campaign.biddingStrategyType],
                rawStrategy: campaign.biddingStrategyType,
                advertisingChannelType: campaign.advertisingChannelType,
            }
        })
    )

    const ineligibleCampaignsTableItems = computed(() =>
        campaignsAndExperiments.value?.ineligibleCampaigns.map(campaign => {
            return {
                id: String(campaign.campaignId),
                campaign: campaign.campaignName,
                cost: campaign.metrics.thirtyDayCost,
                conversions: campaign.metrics.thirtyDayConversions,
                impressions: campaign.metrics.thirtyDayImpressions,
                convValue: campaign.metrics.thirtyDayConversionsValue,
                cpa: campaign.metrics.thirtyDayCPA || 0,
                roas: campaign.metrics.thirtyDayROAS,
                strategy: biddingStrategyTypeMap[campaign.biddingStrategyType],
                rawStrategy: campaign.biddingStrategyType,
                advertisingChannelType: campaign.advertisingChannelType,
                firstCheckThatFailsText: campaign?.firstCheckThatFailsText,
            }
        })
    )

    const allCampaignsTableItems = computed(() => {
        return [
            ...(eligibleCampaignsTableItems.value ?? []),
            ...(ineligibleCampaignsTableItems.value ?? []),
        ]
    })

    const { push, currentRoute } = useRouter()

    const goToCreateFlow = ({
        from,
        campaignIds,
        recommendedBiddingStrategy,
    }: {
        from: 'table' | 'recommendation'
        campaignIds?: string[]
        recommendedBiddingStrategy?: enums.BiddingStrategyType
    }) => {
        if (
            from === 'recommendation' &&
            experimentRecommendations.value &&
            recommendedBiddingStrategy
        ) {
            const createFlowTableItems = eligibleCampaignsTableItems.value?.filter(item =>
                campaignIds?.includes(String(item.id))
            )

            if (!createFlowTableItems) {
                return
            }

            selectedCampaigns.value = createFlowTableItems.map(item => {
                return { ...item, selected: true, id: String(item.id) }
            }) as CampaignTableItem[]

            setupCreateFormFields({ from, recommendedBiddingStrategy }) // set form fields back to default values
        }

        if (from === 'table') {
            setupCreateFormFields({ from }) // set form fields back to default values
        }

        push({ name: Routes.ToolkitSmartBiddingExperimentsCreateFlow })
    }

    const removeSelectedCampaign = (campaignId: string) => {
        selectedCampaigns.value = selectedCampaigns.value.filter(item => item.id !== campaignId)
    }

    const updateExperimentStatus = function (
        experiment: SmartBiddingExperiments.GetAccountExperimentDataResponse,
        status: SmartBiddingExperiments.OpteoExperimentStatus
    ) {
        experiment.status = status
    }

    const getIsExperimentCompleted = function (
        experimentStatus: SmartBiddingExperiments.OpteoExperimentStatus
    ) {
        return (
            experimentStatus === SmartBiddingExperiments.OpteoExperimentStatus.COMPLETED ||
            experimentStatus === SmartBiddingExperiments.OpteoExperimentStatus.REMOVING ||
            experimentStatus === SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
        )
    }
    const getIsExperimentLearning = function (
        experimentStatus?: SmartBiddingExperiments.OpteoExperimentStatus
    ) {
        return experimentStatus === SmartBiddingExperiments.OpteoExperimentStatus.LEARNING
    }

    const completeExperiment = async function () {
        const experiment = currentExperiment.value
        if (!experiment) {
            throw new Error('Cannot complete experiment without an experiment')
        }

        const completeExperimentToast = showAsyncPush({
            hideAfterSuccessMs: 6000,
        })

        const request: SmartBiddingExperiments.CompleteExperimentRequest = {
            experiment: experiment,
        }

        mutateGetAccountExperimentData(() => [
            ...(experimentData.value?.filter(
                item => item.optExperimentId !== experiment.optExperimentId
            ) ?? []),
            {
                ...experiment,
                status: OpteoExperimentStatus.COMPLETING,
            },
        ])

        push({ name: Routes.ToolkitSmartBiddingExperimentsActive })

        // Edit this timeout to change when the progress bar jumps to 25%
        setTimeout(() => completeExperimentToast.setProgress(0.25), 1000)

        const [{ succeeded }] = await Promise.all([
            authRequest(Endpoint.CompleteExperimentRequest, {
                body: { request },
            }),
            delay(3000),
        ])

        await Promise.all([mutateGetAccountExperimentData(), mutateGetCampaignsAndExperiments()])

        if (succeeded) {
            completeExperimentToast.showSuccessState()
        } else {
            completeExperimentToast.showErrorState()
        }
    }

    const openExperiment = function (
        experimentData: SmartBiddingExperiments.GetAccountExperimentDataResponse
    ) {
        push({
            name: Routes.ToolkitSmartBiddingExperimentsExperiment,
            params: { experimentId: experimentData.optExperimentId },
        })
    }

    // Used primarily for the single experiment page
    const currentExperiment = computed(() => {
        const currentExperimentId = currentRoute.value.params.experimentId
        if (!currentExperimentId) {
            return
        }
        return experimentData.value?.find(e => e.optExperimentId === currentExperimentId)
    })

    const formatBiddingStrategy = function (
        biddingStrategyEnum: enums.BiddingStrategyType,
        settings?: SmartBiddingExperiments.ExperimentSettings.ExperimentSettings
    ) {
        if (
            (settings as SmartBiddingExperiments.ExperimentSettings.manualCpcSearchSettings)
                ?.useEnhancedCpc
        ) {
            return biddingStrategyTypeMap[enums.BiddingStrategyType.ENHANCED_CPC]
        }

        if (
            (
                settings as SmartBiddingExperiments.ExperimentSettings.maximiseConversionValueSearchSettings
            )?.isTargetRoas
        ) {
            return biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_ROAS]
        }

        if (
            (
                settings as SmartBiddingExperiments.ExperimentSettings.maximiseConversionsSearchSettings
            )?.isTargetCpa
        ) {
            return biddingStrategyTypeMap[enums.BiddingStrategyType.TARGET_CPA]
        }

        return biddingStrategyTypeMap[biddingStrategyEnum]
    }

    const selectedCampaigns = ref<CampaignTableItem[]>([])

    const selectCampaign = function (campaign: CampaignTableItem) {
        if (checkIfDifferentCampaignTypeOrUnavailable(campaign)) {
            return
        }
        campaign.selected = !campaign.selected
        if (campaign.selected) {
            selectedCampaigns.value.push(campaign)
        } else {
            removeSelectedCampaign(campaign.id)
        }
    }

    const clearSelectedCampaigns = () => {
        selectedCampaigns.value.forEach(item => (item.selected = false))
        selectedCampaigns.value = []
    }

    const showEligibleCampaigns = ref(true)

    const toggleEligibleCampaigns = function () {
        showEligibleCampaigns.value = !showEligibleCampaigns.value
        selectedCampaigns.value = []
    }

    const baseCampaignTableHeaders = [
        { key: 'campaign', text: 'Campaign', sortable: true, removePadding: true },
        { key: 'cost', text: 'Cost', sortable: true, width: 110 },
        { key: 'conversions', text: 'Conv.', sortable: true, width: 95 },
        { key: 'cpa', text: 'CPA', sortable: true, width: 110 },
        { key: 'convValue', text: 'Value', sortable: true, width: 110 },
        { key: 'roas', text: 'ROAS', sortable: true, width: 105 },
        { key: 'strategy', text: 'Strategy', sortable: true, width: 160 },
    ]

    // This is used to decide whether a checkbox is disabled or not.
    // We only want to be able to select campaigns with the same bidding strategy and advertising channel type
    const checkIfDifferentCampaignTypeOrUnavailable = (campaign: CampaignTableItem) => {
        const ineligibleCampaigns = ineligibleCampaignsTableItems.value?.map(c => c.id)
        if ((ineligibleCampaigns?.indexOf(String(campaign.id)) ?? -1) > -1) {
            return true
        }
        if (selectedCampaigns.value.length > 0) {
            return (
                selectedCampaigns.value[0]?.strategy !== campaign.strategy ||
                selectedCampaigns.value[0]?.advertisingChannelType !==
                    campaign.advertisingChannelType
            )
        }
        return false
    }

    // Show/hide batch bar
    const showBatchBar = computed(() => {
        return selectedCampaigns.value.length > 0 ? true : false
    })

    const isRecommendation = ref(false)

    const experimentCreationFormBaseDefaults: ExperimentCreationFormBase = {
        experimentName: '', // TODO: add autofill
        experimentDescription: '', // TODO: add autofill
        experimentBaseBudgetAllocation: 50,
        experimentDurationDays: 90,
    }

    const searchExperimentCreationFormDefaults: SearchExperimentCreationForm = {
        experimentBiddingStrategy: undefined,
        // Target Impression Share Settings
        targetImpressionShareLocation: enums.TargetImpressionShareLocation.TOP_OF_PAGE,
        targetImpressionSharePercentage: '',
        targetImpressionShareMaxCpcBidLimit: '',
        // Target ROAS
        maximiseConversionValueIsTargetRoas: false,
        maximiseConversionValueTargetRoas: '',
        // Maximise Conversion Value
        maximiseConversionValueMaxCpcBidLimit: '',
        // Maximise Conversions
        maximiseConversionsIsTargetCpa: true,
        maximiseConversionsTargetCpa: '',
        maximiseConversionsMaxCpcBidLimit: '',
        // Maximise Clicks
        targetSpendSetMaxCpc: true,
        targetSpendMaxCpc: '',
        // Manual CPC
        manualCpcUseEnhancedCpc: true,
        manualCpcMaxAdGroupCpcBidLimit: '',
    }

    const displayExperimentCreationFormDefaults: DisplayExperimentCreationForm = {
        experimentBiddingStrategy: undefined,
        // Maximise Clicks
        targetSpendSetMaxCpc: true,
        targetSpendMaxCpc: '',
        // Target ROAS
        targetRoas: '',
        // Target CPA
        targetCpa: '',
        // Manual CPC
        manualCpcUseEnhancedCpc: true,
        manualCpcMaxAdGroupCpcBidLimit: '',
    }

    const experimentCreationFormBase = ref(experimentCreationFormBaseDefaults)
    const searchExperimentCreationForm = ref(searchExperimentCreationFormDefaults)
    const displayExperimentCreationForm = ref(displayExperimentCreationFormDefaults)

    const setRecommendedDefaults = () => {
        const selectedCampaignIds = selectedCampaigns.value.map(c => c.id)
        if (!campaignsAndExperiments.value) {
            return
        }
        const relevantCampaigns = campaignsAndExperiments.value.eligibleCampaigns.filter(c =>
            selectedCampaignIds.includes(String(c.campaignId))
        )

        const summedClicks = sumBy(relevantCampaigns, item => item.metrics.thirtyDayClicks)
        const summedCost = sumBy(relevantCampaigns, item => item.metrics.thirtyDayCost)
        const summedConvValue = sumBy(
            relevantCampaigns,
            item => item.metrics.thirtyDayConversionsValue
        )
        const summedConv = sumBy(relevantCampaigns, item => item.metrics.thirtyDayConversions)

        const averageCpc = summedClicks > 0 ? summedCost / summedClicks : 1
        const averageCpa = summedConv > 0 ? summedCost / summedConv : 1
        const averageRoas = summedCost > 0 ? summedConvValue / summedCost : 100

        if (isAdvertisingChannelTypeSearch.value) {
            // Search impression share
            const summedImpressionShareWeightedPercentage = sumBy(
                relevantCampaigns,
                item => item.metrics.impressionSharePercent * item.metrics.thirtyDayImpressions
            )

            const summedImpression = sumBy(
                relevantCampaigns,
                item => item.metrics.thirtyDayImpressions
            )
            searchExperimentCreationForm.value.targetImpressionSharePercentage = Math.min(
                round((summedImpressionShareWeightedPercentage / summedImpression) * 1.1 * 100),
                95
            )
            searchExperimentCreationForm.value.targetImpressionShareMaxCpcBidLimit = round(
                averageCpc * 1.5,
                2
            )

            // maximisise conversion value
            searchExperimentCreationForm.value.maximiseConversionValueTargetRoas = round(
                averageRoas * 1.1 * 100
            )
            searchExperimentCreationForm.value.maximiseConversionValueMaxCpcBidLimit = round(
                averageCpc * 2,
                2
            )

            // maximise conversions
            searchExperimentCreationForm.value.maximiseConversionsTargetCpa = round(
                averageCpa * 0.9,
                2
            )
            searchExperimentCreationForm.value.maximiseConversionsMaxCpcBidLimit = round(
                averageCpc * 2,
                2
            )

            // manual CPC
            searchExperimentCreationForm.value.manualCpcMaxAdGroupCpcBidLimit = round(
                averageCpc * 1.3,
                2
            )

            // target spend
            searchExperimentCreationForm.value.targetSpendMaxCpc = round(averageCpc * 1.5, 2)
        } else {
            // target spend
            displayExperimentCreationForm.value.targetSpendMaxCpc = round(averageCpc * 1.5, 2)

            // target ROAS
            displayExperimentCreationForm.value.targetRoas = round(averageRoas * 1.1 * 100)

            // target CPA
            displayExperimentCreationForm.value.targetCpa = round(averageCpa * 0.9, 2)

            // manual CPC
            displayExperimentCreationForm.value.manualCpcMaxAdGroupCpcBidLimit = round(
                averageCpc * 1.3,
                2
            )
        }
    }

    const setupCreateFormFields = ({
        from,
        recommendedBiddingStrategy,
    }: {
        from: 'table' | 'recommendation'
        recommendedBiddingStrategy?: enums.BiddingStrategyType
    }) => {
        // This will be used in the create request to determine if the experiment is a recommendation
        isRecommendation.value = from === 'recommendation'

        experimentCreationFormBase.value = cloneDeep(experimentCreationFormBaseDefaults)
        searchExperimentCreationForm.value = cloneDeep(searchExperimentCreationFormDefaults)
        displayExperimentCreationForm.value = cloneDeep(displayExperimentCreationFormDefaults)

        // duration calculation
        experimentCreationFormBase.value.experimentDurationDays = durationCalculation()

        if (from === 'recommendation' && recommendedBiddingStrategy) {
            setRecommendedDefaults()

            if (isAdvertisingChannelTypeSearch.value) {
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.TARGET_ROAS) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                    searchExperimentCreationForm.value.maximiseConversionValueIsTargetRoas = true
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.TARGET_CPA) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS
                    searchExperimentCreationForm.value.maximiseConversionsIsTargetCpa = true
                }
                if (
                    recommendedBiddingStrategy ===
                    enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                ) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                    searchExperimentCreationForm.value.maximiseConversionsIsTargetCpa = false
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS
                    searchExperimentCreationForm.value.maximiseConversionsIsTargetCpa = false
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.ENHANCED_CPC) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MANUAL_CPC
                    searchExperimentCreationForm.value.manualCpcUseEnhancedCpc = true
                }
                if (
                    recommendedBiddingStrategy === enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE
                ) {
                    searchExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE
                }
            } else {
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.TARGET_ROAS) {
                    displayExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.TARGET_ROAS
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.TARGET_CPA) {
                    displayExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.TARGET_CPA
                }
                if (
                    recommendedBiddingStrategy ===
                    enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                ) {
                    displayExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS) {
                    displayExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS
                }
                if (recommendedBiddingStrategy === enums.BiddingStrategyType.ENHANCED_CPC) {
                    displayExperimentCreationForm.value.experimentBiddingStrategy =
                        enums.BiddingStrategyType.MANUAL_CPC
                    displayExperimentCreationForm.value.manualCpcUseEnhancedCpc = true
                }
            }
        }
    }

    const durationCalculation = () => {
        const summedThirtyDayConversions = selectedCampaigns.value?.reduce(
            (acc, item) => acc + item.conversions,
            0
        )

        if (summedThirtyDayConversions < 30) {
            return 90
        } else if (summedThirtyDayConversions <= 40) {
            return 60
        } else if (summedThirtyDayConversions <= 50) {
            return 45
        }
        return 30
    }

    const isAdvertisingChannelTypeSearch = computed(
        () =>
            selectedCampaigns.value[0]?.advertisingChannelType ===
            enums.AdvertisingChannelType.SEARCH
    )

    const toProvide = {
        // ui
        showBatchBar,
        clearSelectedCampaigns,
        // domain
        domainInitials,
        domainColour,
        domainId,
        // experiments
        experimentData,
        progressStep,
        currentExperiment,
        hasEligibleCampaigns,
        hasRecommendations,
        hasActiveExperiments,
        experimentRecommendations,
        experimentCarouselSlides,
        activeExperiments,
        completedExperiments,
        getIsExperimentCompleted,
        eligibleCampaignsTableItems,
        ineligibleCampaignsTableItems,
        allCampaignsTableItems,
        loading,
        experimentDataLoading,
        baseCampaignTableHeaders,
        route,
        getDataSetup,
        getHasEnoughData,
        getCampaignExperimentEntityPill,
        completeExperiment,
        getRelevantPerformanceMetricType,
        formatBiddingStrategy,
        Targets,
        activeExperimentsCount,
        completedExperimentsCount,
        getPercentComplete,
        getDaysLeft,
        getTotalDays,
        isExperimentEndEarly,
        getIsExperimentDurationComplete,
        getIsConfidenceAboveNinetyFivePercent,
        selectCampaign,
        selectedCampaigns,
        showEligibleCampaigns,
        toggleEligibleCampaigns,
        goToCreateFlow,
        removeSelectedCampaign,
        checkIfDifferentCampaignTypeOrUnavailable,
        updateExperimentStatus,
        getIsExperimentLearning,
        mutateGetCampaignsAndExperiments,
        mutateGetAccountExperimentData,
        displayBiddingStrategiesSelectItems,
        searchBiddingStrategiesSelectItems,
        allBiddingStrategiesSelectItems,
        currencySymbol,
        campaignsAndExperiments,
        openExperiment,
        isNewExperiment,

        // createFlow
        experimentCreationFormBase,
        searchExperimentCreationForm,
        displayExperimentCreationForm,
        isAdvertisingChannelTypeSearch,
        latestCreatedExperimentId,
        isRecommendation,
    }

    provide('experimentsData', toProvide)

    return toProvide
}

export function useExperiments() {
    const injected = inject<ReturnType<typeof provideExperiments>>('experimentsData')

    if (!injected) {
        throw new Error(
            `Experiments data not yet injected, something is wrong. useExperiments() can only be called in a toolkit/tools/experiments/.`
        )
    }

    return injected
}
